import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
// import PropTypes from "prop-types";
import classNames from "classnames";

import Tooltip from "components/Tooltip";

import { setAlert } from "reducers/actions";

import server from "helper/server";
// import helper from "helper/helper";
import fileHelper from "helper/fileHelper";

import uploadImg from "assets/cloud-upload-regular-240.png";
import excelImg from "assets/file-excel-solid-240.png";

const ExcelUpload = (props) => {
  const dispatch = useDispatch();

  const { id, valueFile, readOnly = false, changeValue } = props;

  const [value, setValue] = useState(fileHelper.defaultValue);

  // refs
  const wrapperRef = useRef(null);

  useEffect(() => {
    getData(id);
  }, [id]);

  useEffect(() => {
    if (valueFile && valueFile !== value) {
      setValue(valueFile);
    }
  }, [valueFile]);

  const onDragEnter = () => wrapperRef.current.classList.add("opacity-60");
  const onDragLeave = () => wrapperRef.current.classList.remove("opacity-60");
  const onDrop = () => wrapperRef.current.classList.remove("opacity-60");

  const getData = async (id) => {
    if (id) {
      const response = await server.baseShow({ objectName: "quotations", id });
      const data = response.data ? response.data.data : null;
      if (data) {
        data.generated_name &&
          setValue({
            file: null,
            fileSrc: null,
            fileInfo: {
              id: data.id,
              link: server.fileURL + "/quotations/" + data.generated_name,
              original_name: data.file_name,
              generated_name: data.generated_name,
              size: data.size,
              extension: data.extension,
            },
          });
      }
    }
  };

  const chooseFile = (e) => {
    const files = e.target.files;
    if (files.length > 1) {
      dispatch(
        setAlert({
          show: true,
          success: false,
          message: "複数のファイルをアップロードしないでください",
        })
      );
      return;
    }

    const file = files.length === 1 ? files[0] : null;
    // console.log({ file: JSON.stringify(file) });
    if (file) {
      if (
        file.type
          .toLowerCase()
          .indexOf(
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ) === -1
      ) {
        dispatch(
          setAlert({
            show: true,
            success: false,
            message: "PDFファイルのみを選択してください",
          })
        );
        return;
      }
      if (file.name.length < 245) {
        // if (file.size > 3145728) {
        //   dispatch(
        //     setAlert({
        //       show: true,
        //       success: false,
        //       message:
        //         "アップロードするファイルの合計サイズは 3MB を超えてはなりません",
        //     })
        //   );
        // } else {
        const newValue = {
          file: file,
          fileSrc: null,
          fileInfo: {
            id: null,
            link: null,
            generated_name: null,
            original_name: file.name,
            size: file.size,
            extension: file.type,
          },
        };
        console.log({ newValue });
        onChangeValue(newValue);
        setValue(newValue);
        // }
      } else {
        dispatch(
          setAlert({
            show: true,
            success: false,
            message: "入力したファイル名が長すぎます",
          })
        );
      }
    }
  };

  const removeFile = () => {
    onChangeValue(fileHelper.defaultValue);
    setValue(null);
  };

  const onChangeValue = (value) => changeValue && changeValue([value]);

  return (
    <>
      {!readOnly && (
        <div
          ref={wrapperRef}
          className={classNames(
            "cursor-pointer",
            "relative",
            "w-full",
            "max-sm:w-full",
            "h-[220px]",
            "max-sm:h-[180px]",
            "border-dashed",
            "border-2",
            "border-primary",
            "flex",
            "items-center",
            "justify-center",
            "bg-transparent",
            "rounded-3xl",
            "hover:opacity-60"
          )}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
        >
          <div className="p-[10px] w-full flex flex-col cursor-pointer">
            <div className="flex justify-center">
              <img src={uploadImg} alt="" className="w-24" />
            </div>
            <p className="text-center text-[#ccc] font-semibold max-sm:text-[0.6rem]">
              ここにファイルをドラッグ アンド
              ドロップするか、クリックしてファイルを選択します
            </p>
            {/* <p className="mt-4 text-center text-[#333] font-semibold max-sm:text-[0.6rem]">
              ファイル形式はEXCEL 300MBまでとなります
            </p> */}
          </div>
          <input
            type="file"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            value=""
            className="cursor-pointer opacity-0 absolute top-0 left-0 w-full h-full"
            onChange={chooseFile}
          />
        </div>
      )}
      {value && value.fileInfo && (value.fileInfo.link || value.file) && (
        <div className="w-full mt-3">
          <div className="relative flex mb-3 bg-[#f5f8ff] dark:bg-gray-700 p-2 rounded-2xl">
            <div className="relative mr-1 rounded-xl w-12">
              <Tooltip
                content={"ビュー"}
                disabled={id ? false : true}
                placement="bottom"
              >
                <img src={excelImg} alt="" className="min-w-[44px]" />
              </Tooltip>
            </div>
            <div className="flex flex-col justify-start items-center mr-12">
              <h5 className="font-semibold dark:text-gray-300 w-full">
                {value.fileInfo.original_name}
              </h5>
              <span className="w-full font-medium text-sm mt-1">
                サイズ: {(parseFloat(value.fileInfo.size) / 1024).toFixed(2)}
                KB
              </span>
            </div>
            <span
              className="bg-white dark:bg-gray-900 w-10 h-10 rounded-full flex items-center justify-center absolute right-5 top-1/2 -translate-y-1/2 shadow-sm cursor-pointer opacity-100 transition-opacity duration-300 ease-in text-gray-700 dark:text-gray-400 hover:text-danger"
              onClick={() => removeFile()}
            >
              <span className="la la-times text-base" />
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default ExcelUpload;
