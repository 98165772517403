import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import moment from "moment";

// redux
import { setAlert } from "reducers/actions";

// import { BaseGridLoading } from "baseComponents";

import helper from "helper/helper";
import server from "helper/server";

const CustomList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [news, setNews] = useState([]);

  useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    setLoading(true);

    let rootFilter = ["status", "=", "active"];
    let skip = 0;
    let take = 5;
    let sort = [{ selector: "id", desc: true }];

    let select = JSON.stringify([
      "*",
      { CreatedUser: ["id", "firstname"] },
      { UpdatedUser: ["id", "firstname"] },
    ]);

    let params = helper.getRequest(
      { skip, take, sort, filter: null },
      rootFilter,
      null
    );
    params += `select=${select}&`;
    params = params.slice(0, -1);

    const response = await server.call({
      url: "/news/grid-list" + params,
      method: "GET",
    });

    if (response.data && response.data.data) {
      if (response.data.success === false) {
        dispatch(setAlert({ show: true, success: false, message: "間違い" }));
      }
      response.data.success && setNews(response.data.data.rows);
    }

    setLoading(false);
  };

  return (
    <div className="container mt-4 max-md:mt-4 max-h-56 overflow-y-auto mx-auto w-[55%] max-xl:w-[85%] max-md:w-full">
      <div className="relative bg-white/80">
        {!loading && news && (
          <div>
            <div className="border-l-4 border-orange-400 border-b border-b-gray-500">
              <span className="h-full flex flex-row items-center p-4 pl-6 text-xl font-light bg-white/40">
                What`s new
              </span>
            </div>
            {Array.isArray(news) &&
              news.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="flex flex-row border-b border-b-gray-400 px-4 py-1 text-sm"
                  >
                    <div className="grid grid-cols-2 max-md:grid-cols-1">
                      <div className="grid grid-cols-2">
                        <div className="px-6 max-sm:px-2 py-1">
                          {helper.setJapanDate(
                            moment(item.created_at).format()
                          )}
                        </div>
                        <div className="px-6 max-sm:px-2 py-1">
                          <span
                            className="text-white text-xs px-6 py-0.5"
                            style={{
                              backgroundColor: helper.typeColor(item.type),
                            }}
                          >
                            {item.type}
                          </span>
                        </div>
                      </div>
                      <div className="py-1 text-left">
                        <span
                          className="cursor-pointer hover:underline text-base"
                          onClick={() => navigate("/auth/news/show/" + item.id)}
                        >
                          {item.title}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomList;
