import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
// redux
import { setAlert } from "reducers/actions";

import { BaseGridLoading, BaseSearchSelect } from "baseComponents";

import server from "helper/server";
import helper from "helper/helper";

const Apartment = (props) => {
  const dispatch = useDispatch();

  const { id = null, role = null, readOnly = false, changeValue } = props;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [additionalFilter, setAdditionalFilter] = useState(null);

  useEffect(() => {
    getList();
  }, [id]);

  useEffect(() => {
    getFilter();
  }, [data]);

  const getFilter = async () => {
    if (data && Array.isArray(data)) {
      let tempIds = [];
      let tempFilter = null;
      data.forEach((e) => tempIds.push(e.apartment_id));
      // console.log({ tempIds });
      if (tempIds.length > 0) {
        tempFilter = ["id", "notIn", tempIds];
      } else {
        tempFilter = null;
      }

      setAdditionalFilter(tempFilter);
    }
  };

  const getList = async () => {
    if (id) {
      setLoading(true);
      let rootFilter = ["detail_id", "=", id];
      let rootSort = null;
      let skip = 0;
      let take = 200;
      let sort = [{ selector: "apartment_id", desc: false }];

      let select = JSON.stringify([
        "id",
        "detail_id",
        "apartment_id",
        { Apartment: ["apartment_name"] },
      ]);
      let params = helper.getRequest(
        { skip, take, sort },
        rootFilter,
        rootSort
      );
      params += `select=${select}&`;
      params = params.slice(0, -1);

      const response = await server.baseList({
        objectName: "constructionDetailToApartment",
        params,
      });

      if (response.data && response.data.data) {
        if (response.data.success === false) {
          dispatch(setAlert({ show: true, success: false, message: "間違い" }));
        }
        response.data.success && setData(response.data.data.rows);
      }
      setLoading(false);
    }
  };

  const removeValue = (id) => {
    let tempData = data;
    tempData = tempData.filter((e) => e.apartment_id !== id);
    changeValue && changeValue("apartments", tempData);
    setData(tempData);
  };

  return (
    <>
      <h4 className="text-gray-700 font-medium text-xl uppercase border-b mb-4">
        マンション
      </h4>
      <div>
        <BaseSearchSelect
          name={"apartment_id"}
          disabled={role !== "Admin" ? true : false}
          placeholder={"マンション"}
          object={true}
          objectName={"apartments"}
          textField={"apartment_name"}
          changeValue={(name, value) => {
            if (value) {
              if (data.some((e) => e.apartment_id === value.id)) {
                dispatch(
                  setAlert({
                    show: true,
                    success: false,
                    position: "top-center",
                    message: "Details are duplicated",
                  })
                );
              } else {
                let tempData = [
                  ...data,
                  {
                    id: null,
                    detail_id: id,
                    apartment_id: value.id,
                    Apartment: { apartment_name: value.text },
                  },
                ];
                changeValue && changeValue("apartments", tempData);
                setData(tempData);
              }
            }
          }}
          additionalFilter={additionalFilter}
        />
      </div>
      <div className="relative min-h-36">
        {loading && <BaseGridLoading />}
        {data &&
          data.map((e, key) => {
            return (
              <div key={key}>
                {e && (
                  <div className="flex flex-row justify-between pr-8 items-center w-full">
                    <div className="px-4 py-2">
                      <p className="text-xl border-b">
                        {e.Apartment ? e.Apartment.apartment_name : ""}
                      </p>
                    </div>
                    {!readOnly && (
                      <span
                        className="la la-times text-white bg-red-700 rounded-full p-1 cursor-pointer hover:bg-red-400"
                        onClick={() => removeValue(e.apartment_id)}
                      />
                    )}
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </>
  );
};

export default Apartment;
