// import TapWeekDays from "customComponents/Weather/TapWeekDays";
import CardWeekDays from "customComponents/Weather/CardWeekDays";
import CategoryGrid from "customComponents/Categories/CustomGrid";
import ConstructionsThumb from "customComponents/Constructions/CustomThumb";
import InquiriesThumb from "customComponents/Inquiries/CustomThumb";
// import Testimonials from "customComponents/Greetings/Testimonials";
import NewTestimonials from "customComponents/Greetings/NewTestimonials";

import { useAuth } from "context/auth";

const NewHomePage = () => {
  const { userData } = useAuth();
  const role = userData ? userData.role : null;

  return (
    <>
      {/* category grid */}
      <CategoryGrid />

      {/* inquire grid */}
      {role === "Customer" && <InquiriesThumb role={role} />}

      {/* construction grid */}
      {role === "Customer" && <ConstructionsThumb role={role} />}

      {/* greetings testimonials */}
      <NewTestimonials />

      <CardWeekDays />
    </>
  );
};

export default NewHomePage;
