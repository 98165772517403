import React, { useState } from "react";
import classNames from "classnames";

import Label from "components/form/Label";

const BaseInputPassword = (props) => {
  const { name, label, helperText, className, invalid, changeValue, ...rest } =
    props;

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  return (
    <div className="mt-3">
      {label && <Label htmlFor="input">{label}</Label>}
      <label className="form-control-addon-within">
        <input
          type={!isPasswordVisible ? "password" : "text"}
          className={classNames(
            "form-control",
            "border-gray-600",
            "text-base",
            "font-light",
            { "is-invalid": invalid },
            className
          )}
          onChange={(event) => {
            changeValue && changeValue(name, event.target.value);
          }}
          {...rest}
        />
        <span className="flex items-center pr-4">
          <button
            type="button"
            className={classNames(
              "text-gray-700 dark:text-gray-300",
              "text-xl",
              "leading-none",
              {
                "la la-eye": isPasswordVisible,
                "la la-eye-slash": !isPasswordVisible,
              }
            )}
            onClick={() => setIsPasswordVisible(!isPasswordVisible)}
          />
        </span>
      </label>
      {helperText && <small className="block mt-2">{helperText}</small>}
    </div>
  );
};

export default BaseInputPassword;
