import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import classNames from "classnames";

import Button from "components/Button";
import Input from "components/form/Input";
import Label from "components/form/Label";

import CustomList from "customComponents/News/CustomList";

import { useAuth } from "context/auth";
import { setAlert } from "reducers/actions";

import background from "assets/images/login-bg.jpeg";

const Login2 = () => {
  const dispatch = useDispatch();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const { login, loginLoading, message } = useAuth();

  useEffect(() => {
    if (message) {
      dispatch(setAlert({ show: true, success: false, message }));
    }
  }, [message]);

  const onSubmit = async (event) => {
    dispatch(setAlert({ show: false }));
    event.preventDefault();
    const data = new FormData(event.target);
    login({ username: data.get("username"), password: data.get("password") });
  };

  return (
    <>
      <div
        className="fixed top-0 right-0 bottom-0 left-0 bg-white dark:bg-gray-800 bg-no-repeat bg-bottom bg-50 max-lg:bg-contain max-sm:bg-cover"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="container flex items-center justify-center mt-24 max-xl:mt-16 max-sm:mt-16 py-5">
          <div className="w-1/4 max-sm:w-5/6 md:w-3/4 lg:w-1/2 xl:w-1/3">
            <form onSubmit={onSubmit}>
              <div className="card mt-2 p-10 rounded-[42px] max-md:p-8">
                <div className="mb-3">
                  <Label className="mb-1 " htmlFor="username">
                    ユーザー名
                  </Label>
                  <label className="form-control-addon-within">
                    <Input
                      id="username"
                      name="username"
                      placeholder="ユーザー名"
                      className="dark:text-gray-300"
                    />
                  </label>
                </div>
                <div className="mb-3">
                  <Label className="mb-1" htmlFor="password">
                    パスワード
                  </Label>
                  <label className="form-control-addon-within">
                    <Input
                      id="password"
                      name="password"
                      placeholder="パスワード"
                      type={isPasswordVisible ? "text" : "password"}
                    />
                    <span className="flex items-center pr-4">
                      <button
                        type="button"
                        className={classNames(
                          "text-gray-300",
                          "dark:text-gray-700",
                          "text-xl",
                          "leading-none",
                          {
                            "la la-eye": isPasswordVisible,
                            "la la-eye-slash": !isPasswordVisible,
                          }
                        )}
                        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                      />
                    </span>
                  </label>
                </div>
                <div className="flex flex-row justify-center mt-4">
                  <Button
                    disabled={loginLoading}
                    className="uppercase rounded-lg w-48 bg-[#6ac2ff]"
                    type="submit"
                  >
                    <div className="w-full flex flex-row justify-center items-center text-center">
                      <span
                        className={`las la-${
                          loginLoading ? "spinner animate-spin" : "sign-in-alt"
                        } text-xl mr-2`}
                      />
                      ログイン
                    </div>
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <CustomList />
      </div>
    </>
  );
};

export default Login2;
