import { useEffect, useState } from "react";
import classNames from "classnames";

import Label from "components/form/Label";

import helper from "helper/helper";

const BaseTextarea = (props) => {
  const {
    label,
    helperText,
    changeValue,
    children,
    value,
    invalid,
    className = "",
    halfWidth = false,
    fullWidth = false,
    ...rest
  } = props;

  const [currentValue, setCurrentValue] = useState(value ? value : "");

  useEffect(() => {
    setCurrentValue(value ? value : "");
  }, [value]);

  return (
    <div className="mb-3">
      {label && <Label htmlFor="input">{label}</Label>}
      <label className="form-control-addon-within">
        <textarea
          className={classNames(
            "form-control",
            { "is-invalid": invalid },
            className
          )}
          onChange={(event) => {
            let value = event.target.value;
            if (fullWidth) value = helper.convertToFull(value);
            if (halfWidth) value = helper.convertToHalf(value);
            setCurrentValue(value);
            changeValue && changeValue(props.name, value);
          }}
          value={currentValue}
          {...rest}
        />
      </label>
      {helperText && <small className="block mt-2">{helperText}</small>}
    </div>
  );
};

export default BaseTextarea;
