import React from "react";
import { useSelector } from "react-redux";

import { BaseSearchSelect, BaseInput, BaseTextarea } from "baseComponents";

import { useAuth } from "context/auth";

const Form = (props) => {
  const { userData } = useAuth();
  const role = userData ? userData.role : null;

  const categoriesEditData = useSelector(
    (state) => state.categories.categoriesEditData
  );
  const { changeModifyObject } = props;

  const changeValue = (name, value) =>
    changeModifyObject && changeModifyObject({ [name]: value });

  return (
    <>
      <div className="grid grid-cols-1">
        <BaseSearchSelect
          label={"マンション"}
          name={"apartment_id"}
          value={
            role !== "Admin"
              ? userData.apartment_id || null
              : categoriesEditData.apartment_id || null
          }
          disabled={role !== "Admin" ? true : false}
          placeholder={"マンション"}
          objectName={"apartments"}
          textField={"apartment_name"}
          changeValue={changeValue}
        />
      </div>
      <div className="grid grid-cols-1">
        <BaseInput
          label={"カテゴリー"}
          name={"category_name"}
          value={categoriesEditData.category_name || null}
          placeholder={"カテゴリー"}
          changeValue={changeValue}
        />
      </div>
      <div className="grid grid-cols-1">
        <BaseTextarea
          label={"説明"}
          name={"description"}
          value={categoriesEditData.description || null}
          placeholder={"説明"}
          changeValue={changeValue}
          className="h-32"
        />
      </div>
    </>
  );
};

export default Form;
