import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// redux
import {
  setAlert,
  setShowGridLoading,
  setEmailsDialog,
  setEmailsList,
  setEmailsModifyData,
  setEmailsEditData,
} from "reducers/actions";

import useEmailsSave from "reducers/hooks/useEmailsSave";
import useEmailDelete from "reducers/hooks/useEmailDelete";

// components
import Tooltip from "components/Tooltip";
import Button from "components/Button";

import { BaseDialog } from "baseComponents";

import Grid from "./Grid";
import Form from "./Form";

import server from "helper/server";
import helper from "helper/helper";

const Emails = () => {
  const dispatch = useDispatch();

  const emailsDialog = useSelector((state) => state.emails.emailsDialog);
  const emailsModifyData = useSelector(
    (state) => state.emails.emailsModifyData
  );
  const emailsEditData = useSelector((state) => state.emails.emailsEditData);

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);

  const [saveEmails] = useEmailsSave();
  const [deleteEmail] = useEmailDelete();

  useEffect(() => {
    getList();
  }, [page, size]);

  const getList = async () => {
    dispatch(setShowGridLoading(true));

    let rootFilter = ["status", "=", "active"];
    let rootSort = null;
    let skip = (page - 1) * size;
    let take = size;
    let sort = [{ selector: "id", desc: true }];

    let select = JSON.stringify([
      "*",
      { Apartment: ["id", "apartment_name"] },
      { CreatedUser: ["id", "firstname"] },
      { UpdatedUser: ["id", "firstname"] },
    ]);
    let params = helper.getRequest(
      { skip, take, sort, filter: null },
      rootFilter,
      rootSort
    );
    params += `select=${select}&`;
    params = params.slice(0, -1);

    const response = await server.baseList({
      objectName: "emails",
      params,
    });

    if (response.data && response.data.data) {
      if (response.data.success === false) {
        dispatch(setAlert({ show: true, success: false, message: "間違い" }));
      }
      response.data.success && dispatch(setEmailsList(response.data.data));
    }

    dispatch(setShowGridLoading(false));
  };

  return (
    <>
      <BaseDialog
        title={"メール登録"}
        isActive={emailsDialog}
        hide={() => {
          dispatch(setAlert({ show: false }));
          dispatch(setEmailsModifyData({}));
          dispatch(setEmailsEditData({}));
          dispatch(setEmailsDialog(false));
        }}
        save={() =>
          saveEmails(emailsModifyData, emailsEditData, () => {
            dispatch(setEmailsModifyData({}));
            dispatch(setEmailsEditData({}));
            dispatch(setEmailsDialog(false));
            getList();
          })
        }
        width={"w-[360px]"}
      >
        <Form
          changeModifyObject={(data) =>
            dispatch(setEmailsModifyData({ ...emailsModifyData, ...data }))
          }
        />
      </BaseDialog>
      <div className="w-full">
        {/* custom actions */}
        <section className="flex items-start w-full gap-2 mb-3">
          {/* Refresh */}
          <Tooltip content={"リフレッシュ"} placement="bottom">
            <Button icon color="info" onClick={() => getList()}>
              <span className="la la-sync" />
            </Button>
          </Tooltip>
          {/* Add New */}
          <Tooltip content={"新しい"} placement="bottom">
            <Button
              icon
              color="success"
              onClick={() => dispatch(setEmailsDialog(true))}
            >
              <span className="la la-plus" />
            </Button>
          </Tooltip>
        </section>
        <Grid
          edit={(data) => {
            dispatch(setEmailsDialog(true));
            dispatch(setEmailsEditData(Object.assign(emailsEditData, data)));
          }}
          destroy={(id) => deleteEmail(id, () => getList())}
          onPageChange={(page) => setPage(page)}
          onPageSizeChange={(size) => setSize(size)}
        />
      </div>
    </>
  );
};

export default Emails;
