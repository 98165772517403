import { FileImport } from "customComponents";

const FileImportPage = () => {
  return (
    <div className="mt-24 mx-auto w-3/4 max-md:w-11/12 bg-white shadow rounded-md min-h-28 p-8 max-md:p-2">
      <FileImport />
    </div>
  );
};

export default FileImportPage;
