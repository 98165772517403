import { useEffect } from "react";
import { useSelector } from "react-redux";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const options = {
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
  transition: null,
};

const Content = (props) => {
  return <span dangerouslySetInnerHTML={{ __html: props.message }} />;
};

const BaseAlertNew = () => {
  const alertData = useSelector((state) => state.custom.alertData);

  useEffect(() => {
    const message = alertData.message;
    const position = alertData.position || "bottom-center";
    if (alertData.show && message !== undefined) {
      toast.dismiss();
      if (alertData.success !== undefined) {
        alertData.success
          ? toast.success(<Content message={message} />, {
              ...options,
              position,
            })
          : toast.error(<Content message={message} />, {
              ...options,
              position,
            });
      }
    }
  }, [alertData]);

  return <ToastContainer />;
};

export default BaseAlertNew;
