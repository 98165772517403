import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// redux
import {
  setAlert,
  setShowGridLoading,
  setNewsDialog,
  setNewsList,
  setNewsModifyData,
  setNewsEditData,
} from "reducers/actions";

import useNewsSave from "reducers/hooks/useNewsSave";
import useNewsDelete from "reducers/hooks/useNewsDelete";

// components
import Tooltip from "components/Tooltip";
import Button from "components/Button";

import { BaseDialog } from "baseComponents";

import Grid from "./Grid";
import Form from "./Form";

import server from "helper/server";
import helper from "helper/helper";

const News = () => {
  const dispatch = useDispatch();

  const newsDialog = useSelector((state) => state.news.newsDialog);
  const newsModifyData = useSelector((state) => state.news.newsModifyData);
  const newsEditData = useSelector((state) => state.news.newsEditData);

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);

  const [saveNews] = useNewsSave();
  const [deleteNews] = useNewsDelete();

  let filter = null;

  useEffect(() => {
    getList();
  }, [page, size]);

  const getList = async () => {
    dispatch(setShowGridLoading(true));

    let rootFilter = ["status", "=", "active"];
    let rootSort = null;
    let skip = (page - 1) * size;
    let take = size;
    let sort = [{ selector: "id", desc: true }];

    let select = JSON.stringify([
      "*",
      { CreatedUser: ["id", "firstname"] },
      { UpdatedUser: ["id", "firstname"] },
    ]);
    let params = helper.getRequest(
      { skip, take, sort, filter },
      rootFilter,
      rootSort
    );
    params += `select=${select}&`;
    params = params.slice(0, -1);

    const response = await server.baseList({
      objectName: "news",
      params,
    });

    if (response.data && response.data.data) {
      if (response.data.success === false) {
        dispatch(setAlert({ show: true, success: false, message: "間違い" }));
      }
      response.data.success && dispatch(setNewsList(response.data.data));
    }

    dispatch(setShowGridLoading(false));
  };

  return (
    <>
      <BaseDialog
        title={"お知らせ"}
        isActive={newsDialog}
        hide={() => {
          dispatch(setAlert({ show: false }));
          dispatch(setNewsModifyData({}));
          dispatch(setNewsEditData({}));
          dispatch(setNewsDialog(false));
        }}
        save={() =>
          saveNews(newsModifyData, newsEditData, () => {
            dispatch(setNewsModifyData({}));
            dispatch(setNewsEditData({}));
            dispatch(setNewsDialog(false));
            getList();
          })
        }
      >
        <Form
          changeModifyObject={(data) =>
            dispatch(setNewsModifyData({ ...newsModifyData, ...data }))
          }
        />
      </BaseDialog>
      <div className="w-full">
        {/* custom actions */}
        <section className="flex items-start w-full gap-2 mb-3">
          {/* Refresh */}
          <Tooltip content={"リフレッシュ"} placement="bottom">
            <Button icon color="info" onClick={() => getList()}>
              <span className="la la-sync" />
            </Button>
          </Tooltip>
          {/* Add New */}
          <Tooltip content={"新しい"} placement="bottom">
            <Button
              icon
              color="success"
              onClick={() => dispatch(setNewsDialog(true))}
            >
              <span className="la la-plus" />
            </Button>
          </Tooltip>
        </section>
        <Grid
          edit={(data) => {
            dispatch(setNewsDialog(true));
            dispatch(setNewsEditData(Object.assign(newsEditData, data)));
          }}
          destroy={(id) => deleteNews(id, () => getList())}
          onPageChange={(page) => setPage(page)}
          onPageSizeChange={(size) => setSize(size)}
        />
      </div>
    </>
  );
};

export default News;
