import React from "react";
import { useSelector } from "react-redux";

import { BaseSearchSelect, BaseInput, BaseTextarea } from "baseComponents";

import { useAuth } from "context/auth";

const Form = (props) => {
  const { userData } = useAuth();
  const role = userData ? userData.role : null;

  const emailsEditData = useSelector((state) => state.emails.emailsEditData);
  const { changeModifyObject } = props;

  const changeValue = (name, value) => {
    changeModifyObject && changeModifyObject({ [name]: value });
  };

  return (
    <>
      <div className="grid grid-cols-1">
        <BaseSearchSelect
          label={"マンション"}
          name={"apartment_id"}
          value={
            role !== "Admin"
              ? userData.apartment_id || null
              : emailsEditData.apartment_id || null
          }
          disabled={role !== "Admin" ? true : false}
          placeholder={"マンション"}
          objectName={"apartments"}
          textField={"apartment_name"}
          changeValue={changeValue}
        />
      </div>
      <div className="grid grid-cols-1">
        <BaseInput
          label={"メール"}
          name={"email"}
          value={emailsEditData.email || ""}
          placeholder={"メール"}
          changeValue={changeValue}
        />
      </div>
      <div className="grid grid-cols-1">
        <BaseTextarea
          label={"説明"}
          name={"description"}
          value={emailsEditData.description || ""}
          placeholder={"説明"}
          changeValue={changeValue}
          className="h-32"
        />
      </div>
    </>
  );
};

export default Form;
