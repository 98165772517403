import { useParams } from "react-router-dom";

import CreateAndUpdate from "customComponents/Inquiries/CreateAndUpdate";

const InquiryShow = () => {
  const { id } = useParams();

  return <CreateAndUpdate id={id} show={true} readOnly={true} />;
};

export default InquiryShow;
