import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import Button from "components/Button";
import { setAlert } from "reducers/actions";

import Image from "customComponents/Apartments/LogoUpload/Image";

import server from "helper/server";
import fileHelper from "helper/fileHelper";

// const imageExtensions = ["png", "jpg", "jpeg", "gif", "webp"];

const LogoUpload = (props) => {
  const dispatch = useDispatch();
  const {
    id = null,
    name,
    multiple = false,
    readOnly = false,
    changeValue,
  } = props;

  const [value, setValue] = useState(null);
  // refs
  const FileInput = useRef();

  useEffect(() => {
    getData(id);
  }, [id]);

  const getData = async (id) => {
    if (id) {
      let newValue = null;
      const response = await server.baseShow({ objectName: "apartments", id });
      const data = response.data ? response.data.data : null;
      if (data) {
        newValue = {
          file: null,
          fileSrc: null,
          fileInfo: {
            id: data.id,
            link: data.generated_name
              ? server.fileURL + "/apartments/" + data.generated_name
              : null,
            original_name: data.file_name,
            generated_name: data.generated_name,
            size: data.size,
            extension: data.extension,
          },
        };
        setValue(newValue);
      }
    }
  };

  const chooseFile = async (e) => {
    e.preventDefault();
    const files = await e.target.files;
    for (let l = 0; l < files.length; l++) {
      const file = files[l];

      let fileSrc = null;
      if (file.type.indexOf("image") > -1) {
        fileSrc = await fileHelper.getFileSrc(file);
      }
      if (file.name.length < 245) {
        const newValue = {
          file: file,
          fileSrc: fileSrc,
          fileInfo: {
            id: null,
            link: null,
            field_name: null,
            generated_name: null,
            original_name: file.name,
            size: file.size,
            extension: file.type,
          },
        };
        if (file.size > 10485760) {
          dispatch(
            setAlert({
              show: true,
              success: false,
              message:
                "アップロードするファイルの合計サイズは 10MB を超えてはなりません",
            })
          );
        } else {
          setValue(newValue);
          onChangeValue(newValue);
        }
      } else {
        dispatch(
          setAlert({
            show: true,
            success: false,
            message: "入力したファイル名が長すぎます",
          })
        );
        break;
      }
    }

    e.target.value = "";
    FileInput.current.value = "";
  };

  const remove = () => {
    onChangeValue(fileHelper.defaultValue);
    setValue(null);
  };

  const onChangeValue = (value) => changeValue && changeValue(name, [value]);

  return (
    <div className="flex flex-col justify-center items-center mt-3">
      {!readOnly && (
        <Button
          type="button"
          disabled={readOnly}
          className="uppercase"
          onClick={() => FileInput.current.click && FileInput.current.click()}
          color={"danger"}
          outlined={true}
        >
          <span className="la la-image text-xl leading-none mr-2" />
          画像を選択してください
        </Button>
      )}
      <input
        ref={FileInput}
        name={name}
        type="file"
        multiple={multiple}
        accept={"image/png, image/gif, image/jpeg"}
        onChange={chooseFile}
        className="hidden"
      />
      <Image
        value={value}
        link={value ? value.fileInfo.link : null}
        readOnly={readOnly}
        remove={remove}
      />
    </div>
  );
};

export default LogoUpload;
