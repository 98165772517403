import { useEffect, useState, forwardRef } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

const BaseSwitch = forwardRef((props, ref) => {
  const {
    checked = false,
    label,
    outlined,
    className,
    changeValue,
    ...rest
  } = props;

  const [currentChecked, setCurrentChecked] = useState(null);

  useEffect(() => {
    setCurrentChecked(checked);
  }, [checked]);

  return (
    <label
      ref={ref}
      className={classNames("switch", { switch_outlined: outlined }, className)}
    >
      <input
        type="checkbox"
        checked={currentChecked}
        onChange={(event) => {
          const checked = event.target.checked;
          setCurrentChecked(checked);
          changeValue && changeValue(props.name, checked);
        }}
        {...rest}
      />
      <span />
      {label ? <span>{label}</span> : null}
    </label>
  );
});

BaseSwitch.propTypes = {
  label: PropTypes.string,
  outlined: PropTypes.bool,
  className: PropTypes.string,
};

export default BaseSwitch;
