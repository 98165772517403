const DetailCard = (props) => {
  const { roomNumber, isNew, onClick } = props;

  return (
    <div className="relative flex flex-row justify-center items-center border mt-4 border-gray-100">
      {isNew && (
        <div className="absolute -top-3 left-1 h-[20px] flex items-center py-1 px-2 rounded-full bg-success font-extrabold text-white dark:text-gray-300 text-xs uppercase mr-3">
          New
        </div>
      )}
      <div className="inline-block">
        <div
          className="cursor-pointer flex flex-col items-center justify-center p-4 text-gray-700 dark:text-gray-300 hover:text-blue-800 hover:bg-gray-100"
          onClick={() => onClick && onClick()}
        >
          <span className="la la-file-alt text-8xl" />
          <span className="text-base">{roomNumber}</span>
        </div>
      </div>
    </div>
  );
};

export default DetailCard;
