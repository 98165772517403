import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// redux
import {
  setAlert,
  setShowGridLoading,
  setGreetingsDialog,
  setGreetingsList,
  setGreetingsModifyData,
  setGreetingsEditData,
} from "reducers/actions";

import useGreetingsSave from "reducers/hooks/useGreetingsSave";
import useGreetingDelete from "reducers/hooks/useGreetingDelete";

// components
import Tooltip from "components/Tooltip";
import Button from "components/Button";

import { BaseDialog } from "baseComponents";

import Grid from "./Grid";
import Form from "./Form";

import server from "helper/server";
import helper from "helper/helper";

const Greetings = () => {
  const dispatch = useDispatch();

  const greetingsDialog = useSelector(
    (state) => state.greetings.greetingsDialog
  );
  const greetingsModifyData = useSelector(
    (state) => state.greetings.greetingsModifyData
  );
  const greetingsEditData = useSelector(
    (state) => state.greetings.greetingsEditData
  );

  const [saveGreetings] = useGreetingsSave();
  const [deleteGreeting] = useGreetingDelete();

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);

  useEffect(() => {
    getList();
  }, [page, size]);

  const getList = async () => {
    dispatch(setShowGridLoading(true));

    let rootFilter = ["status", "=", "active"];
    let rootSort = null;
    let skip = (page - 1) * size;
    let take = size;
    let sort = [{ selector: "id", desc: true }];

    let select = JSON.stringify([
      "*",
      { Apartment: ["id", "apartment_name"] },
      { CreatedUser: ["id", "firstname"] },
      { UpdatedUser: ["id", "firstname"] },
    ]);
    let params = helper.getRequest(
      { skip, take, sort, filter: null },
      rootFilter,
      rootSort
    );
    params += `select=${select}&`;
    params = params.slice(0, -1);

    const response = await server.baseList({
      objectName: "greetings",
      params,
    });

    if (response.data && response.data.data) {
      if (response.data.success === false) {
        dispatch(setAlert({ show: true, success: false, message: "間違い" }));
      }
      response.data.success && dispatch(setGreetingsList(response.data.data));
    }

    dispatch(setShowGridLoading(false));
  };

  return (
    <>
      <BaseDialog
        title={"ご挨拶"}
        isActive={greetingsDialog}
        hide={() => {
          dispatch(setAlert({ show: false }));
          dispatch(setGreetingsModifyData({}));
          dispatch(setGreetingsEditData({}));
          dispatch(setGreetingsDialog(false));
        }}
        save={() =>
          saveGreetings(greetingsModifyData, greetingsEditData, () => {
            dispatch(setGreetingsModifyData({}));
            dispatch(setGreetingsEditData({}));
            dispatch(setGreetingsDialog(false));
            getList();
          })
        }
        width={"w-[460px]"}
      >
        <Form
          changeModifyObject={(data) =>
            dispatch(
              setGreetingsModifyData({ ...greetingsModifyData, ...data })
            )
          }
        />
      </BaseDialog>
      <div className="w-full">
        {/* custom actions */}
        <section className="flex items-start w-full gap-2 mb-3">
          {/* Refresh */}
          <Tooltip content={"リフレッシュ"} placement="bottom">
            <Button icon color="info" onClick={() => getList()}>
              <span className="la la-sync" />
            </Button>
          </Tooltip>
          {/* Add New */}
          <Tooltip content={"新しい"} placement="bottom">
            <Button
              icon
              color="success"
              onClick={() => dispatch(setGreetingsDialog(true))}
            >
              <span className="la la-plus" />
            </Button>
          </Tooltip>
        </section>
        <Grid
          edit={(data) => {
            dispatch(setGreetingsDialog(true));
            dispatch(
              setGreetingsEditData(Object.assign(greetingsEditData, data))
            );
          }}
          destroy={(id) => deleteGreeting(id, () => getList())}
          onPageChange={(page) => setPage(page)}
          onPageSizeChange={(size) => setSize(size)}
        />
      </div>
    </>
  );
};

export default Greetings;
