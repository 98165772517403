import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// redux
import { setUsersFilter } from "reducers/actions";

import { BaseSearchSelect, BaseInput, BaseTextarea } from "baseComponents";
import ProfilePicture from "customComponents/Greetings/ProfilePicture";

import { useAuth } from "context/auth";

const Form = (props) => {
  const dispatch = useDispatch();

  const { userData } = useAuth();
  const role = userData ? userData.role : null;

  const greetingsEditData = useSelector(
    (state) => state.greetings.greetingsEditData
  );
  const usersFilter = useSelector((state) => state.greetings.usersFilter);

  const { changeModifyObject } = props;

  useEffect(() => {
    dispatch(setUsersFilter(null));
    role !== "Admin" &&
      dispatch(setUsersFilter(["apartment_id", "=", userData.apartment_id]));
  }, []);

  useEffect(() => {
    if (greetingsEditData.id && greetingsEditData.apartment_id) {
      dispatch(
        setUsersFilter(["apartment_id", "=", greetingsEditData.apartment_id])
      );
    }
  }, [greetingsEditData]);

  const changeValue = (name, value) => {
    if (name === "apartment_id") {
      dispatch(setUsersFilter(["apartment_id", "=", value]));
      changeModifyObject && changeModifyObject({ user_id: null });
    }
    changeModifyObject && changeModifyObject({ [name]: value });
  };

  return (
    <>
      <div className="grid grid-cols-1">
        <ProfilePicture
          id={greetingsEditData.id || null}
          name={"profile"}
          changeValue={changeValue}
        />
      </div>
      <div className="grid grid-cols-1">
        <BaseSearchSelect
          label={"マンション"}
          name={"apartment_id"}
          value={
            role !== "Admin"
              ? userData.apartment_id || null
              : greetingsEditData.apartment_id || null
          }
          disabled={role !== "Admin" ? true : false}
          placeholder={"マンション"}
          objectName={"apartments"}
          textField={"apartment_name"}
          changeValue={changeValue}
        />
      </div>
      <div className="grid grid-cols-1">
        <BaseSearchSelect
          label={"ユーザー名"}
          name={"user_id"}
          value={
            role !== "Admin"
              ? userData.id || null
              : greetingsEditData.user_id || null
          }
          disabled={role !== "Admin" ? true : false}
          placeholder={"ユーザー名"}
          objectName={"users"}
          textField={"username"}
          changeValue={changeValue}
          customFilter={["role", "=", "Creator"]}
          additionalFilter={usersFilter || ["apartment_id", "=", null]}
        />
      </div>
      <div className="grid grid-cols-1">
        <BaseInput
          label={"題名"}
          name={"title"}
          value={greetingsEditData.title || ""}
          placeholder={"題名"}
          changeValue={changeValue}
        />
      </div>
      <div className="grid grid-cols-1">
        <BaseTextarea
          label={"コメント"}
          name={"comment"}
          value={greetingsEditData.comment || ""}
          placeholder={"コメント"}
          changeValue={changeValue}
          className="h-32"
        />
      </div>
    </>
  );
};

export default Form;
