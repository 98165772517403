import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import Tooltip from "components/Tooltip";
import { setAlert } from "reducers/actions";

import server from "helper/server";
import helper from "helper/helper";
import fileHelper from "helper/fileHelper";

import defaultImg from "assets/profile-default.png";

// const imageExtensions = ["png", "jpg", "jpeg", "gif", "webp"];

const ProfilePicture = (props) => {
  const dispatch = useDispatch();
  const { id = null, name, multiple = false, readOnly = false } = props;

  const [value, setValue] = useState(null);
  // refs
  const FileInput = useRef();

  useEffect(() => {
    if (id) getData(id);
  }, [id]);

  const getData = async (id) => {
    if (id) {
      let newValue = [];
      const response = await server.baseShow({ objectName: "greetings", id });
      const data = response.data ? response.data.data : null;
      if (data) {
        newValue = {
          file: null,
          fileSrc: null,
          fileInfo: {
            id: data.id,
            link: data.generated_name
              ? server.fileURL + "/greetings/" + data.generated_name
              : null,
            field_name: data.field_name,
            original_name: data.file_name,
            generated_name: data.generated_name,
            size: data.size,
            extension: data.extension,
          },
        };
        setValue(newValue);
      }
    }
  };

  const chooseFile = async (e) => {
    e.preventDefault();
    const files = await e.target.files;

    for (let l = 0; l < files.length; l++) {
      const file = files[l];
      let fileSrc = null;
      if (file.type.indexOf("image") > -1) {
        fileSrc = await fileHelper.getFileSrc(file);
      }
      if (file.name.length < 245) {
        const newValue = {
          file: file,
          fileSrc: fileSrc,
          fileInfo: {
            id: null,
            link: null,
            generated_name: null,
            original_name: file.name,
            size: file.size,
            extension: file.type,
          },
        };
        if (file.size > 10485760) {
          dispatch(
            setAlert({
              show: true,
              success: false,
              message:
                "アップロードするファイルの合計サイズは 10MB を超えてはなりません",
            })
          );
        } else {
          setValue(newValue);
          changeValue(newValue);
        }
      } else {
        dispatch(
          setAlert({
            show: true,
            success: false,
            message: "入力したファイル名が長すぎます",
          })
        );
        break;
      }
    }

    e.target.value = "";
    FileInput.current.value = "";
  };

  const remove = () => {
    changeValue(fileHelper.defaultValue);
    setValue(null);
  };

  const changeValue = (value) => {
    props.changeValue && props.changeValue(name, [value]);
  };

  return (
    <div className="flex flex-col justify-center items-center mt-3">
      <input
        ref={FileInput}
        name={name}
        type="file"
        multiple={multiple}
        accept={"image/png, image/gif, image/jpeg"}
        onChange={chooseFile}
        className="hidden"
      />
      <div className="relative mt-2">
        {value &&
          value.fileInfo &&
          !readOnly &&
          (helper.urlExists(value.fileInfo.link) || value.fileSrc) && (
            <div className="absolute top-3 right-5 z-99 cursor-pointer">
              <span
                className="la la-times-circle bg-white shadow-md shadow-gray-600 text-2xl text-gray-500 rounded-full hover:bg-gray-500 hover:text-white z-99"
                onClick={() => remove()}
              />
            </div>
          )}
        <div className="inline-block relative rounded-full shadow-gray-500 overflow-hidden z-50">
          {!readOnly && (
            <div className="absolute top-auto left-0 right-0 bottom-0 inline-block h-16 cursor-pointer z-40 group">
              <div
                className="hidden h-16 bg-gray-600 bg-opacity-25 group-hover:flex group-hover:flex-row group-hover:justify-center group-hover:items-center"
                onClick={() =>
                  FileInput.current.click && FileInput.current.click()
                }
              >
                <span className="hidden group-hover:block la la-cloud-upload-alt text-5xl text-gray-400"></span>
              </div>
            </div>
          )}
          {value && value.fileInfo ? (
            <div>
              <div className={"thumbnail"}>
                <div className={"image-panel overflow-hidden"}>
                  <img
                    src={
                      helper.urlExists(value.fileInfo.link)
                        ? value.fileInfo.link
                        : value.fileSrc
                        ? value.fileSrc
                        : defaultImg
                    }
                    className="w-full h-full object-cover"
                    alt={value.fileInfo.file_name}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className={"thumbnail"}>
              <div className={"image-panel overflow-hidden"}>
                <img
                  src={defaultImg}
                  className="w-full h-full object-cover"
                  alt={"Default Image"}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfilePicture;
