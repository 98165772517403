import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const ConstructionFinishPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/admin/home");
    }, 5000);
  }, [id]);

  return (
    <div className="bg-gray-200 w-full min-h-[500px] flex flex-row justify-center items-center">
      <p className="inline-block text-center text-3xl">
        オプション工事を受け付けました
        <br />
        <br />
        ありがとうございます
        <div className="text-red-700 font-medium mt-4">
          後日　工事日程等のお知らせを
          <br />
          <br />
          書面にてご案内いたします
        </div>
      </p>
    </div>
  );
};

export default ConstructionFinishPage;
