import { BackToHome } from "additionalComponents";
import CustomGrid from "customComponents/Inquiries/CustomThumb";

import { useAuth } from "context/auth";

const OwnInquiriesPage = () => {
  const { userData } = useAuth();
  const role = userData ? userData.role : null;
  return (
    <>
      <BackToHome />
      <CustomGrid role={role} />
    </>
  );
};

export default OwnInquiriesPage;
