import { BaseCard } from "baseComponents";
import { Greetings } from "customComponents";

const GreetingsPage = () => {
  return (
    <BaseCard title={"ご挨拶"}>
      <Greetings />
    </BaseCard>
  );
};

export default GreetingsPage;
