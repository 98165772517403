import { createContext, useContext, useRef } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";

import Backdrop from "components/Backdrop";

import useOnClickOutside from "reducers/hooks/useOnClickOutside";

const ModalContext = createContext();

const Modal = (props) => {
  const { active, staticBackdrop = false, aside, onClose, children } = props;

  const modalContentRef = useRef();

  // Static Backdrop
  useOnClickOutside(modalContentRef, () => {
    if (!staticBackdrop) onClose();
  });

  const animations = aside
    ? {
        enterActive: "animate__animated animate__faster animate__fadeInRight",
        exitActive: "animate__animated animate__faster animate__fadeOutRight",
      }
    : {
        enterActive: "animate__animated animate__faster animate__fadeInDown",
        exitActive: "animate__animated animate__faster animate__fadeOutUp",
      };

  return (
    <ModalContext.Provider value={{ onClose }}>
      <Backdrop active={active} />
      <CSSTransition
        nodeRef={modalContentRef}
        in={active}
        timeout={200}
        classNames={animations}
        unmountOnExit
      >
        <div className="modal active overflow-hidden">
          <div className="modal-dialog min-lg:flex justify-center items-center min-md:block">
            <div ref={modalContentRef} className="modal-content">
              {children}
            </div>
          </div>
        </div>
      </CSSTransition>
    </ModalContext.Provider>
  );
};

Modal.propTypes = {
  active: PropTypes.bool,
  staticBackdrop: PropTypes.bool,
  aside: PropTypes.bool,
  onClose: PropTypes.func,
};

const ModalHeader = (props) => {
  const { children } = props;

  const { onClose } = useContext(ModalContext);

  return (
    <div className="modal-header">
      <h3 className="modal-title">{children}</h3>
      <button className="close la la-times" onClick={onClose} />
    </div>
  );
};

const ModalBody = (props) => {
  const { className, children, ...rest } = props;

  return (
    <div
      className={classNames("max-h-5/7", "overflow-y-auto", className)}
      {...rest}
    >
      {children}
    </div>
  );
};

const ModalFooter = (props) => {
  const { children } = props;

  return <div className="modal-footer">{children}</div>;
};

export default Modal;

export { ModalHeader, ModalBody, ModalFooter };
