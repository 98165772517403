import React from "react";

// Admin
import Home from "pages/Home";
import NewHome from "pages/NewHome";
import Category from "pages/Category";
import Information from "pages/Information";
import Users from "pages/Users";
import Documents from "pages/Documents";
import Categories from "pages/Categories";
import Apartments from "pages/Apartments";
import Emails from "pages/Emails";

// new develope
import ConstructionDetails from "pages/ConstructionDetails";
import Constructions from "pages/Constructions";
import Inquiries from "pages/Inquiries";
import Greetings from "pages/Greetings";
import News from "pages/News";
// import ConstructionsRoomGrid from "pages/ConstructionsRoomGrid";

//

// contruction custom
import ConstructionCustomDetail from "pages/ConstructionCustomDetail";
import ConstructionRoomDetail from "pages/ConstructionsRoomDetail";
import ConstructionCreate from "pages/ConstructionCreate";
import ConstructionCheck from "pages/ConstructionCheck";
// import ConstructionConfirm from "pages/ConstructionConfirm";
import ConstructionFinish from "pages/ConstructionFinish";

import OwnConstructions from "pages/OwnConstructions";

// inquiry custom
import InquiryShow from "pages/InquiryShow";
import InquiryCreateAndUpdate from "pages/InquiryCreateAndUpdate";
import InquiryFinish from "pages/InquiryFinish";

import OwnInquiries from "pages/OwnInquiries";

import NewsShow from "pages/NewsShow";

// Auth
import Login from "pages/Login";
import Login2 from "pages/Login2";

// Test
import ExcelImport from "pages/Test/ExcelImport";
import FileImport from "pages/Mobile/FileImport";

const routes = [
  {
    layout: "/auth",
    path: "login",
    redirect: true,
    // component: <Login />,
    component: <Login2 />,
  },

  // Mobile

  {
    layout: "/auth",
    path: "mobile/file/import",
    redirect: true,
    component: <FileImport />,
  },

  // Test
  {
    layout: "/auth",
    path: "excel/import",
    redirect: true,
    component: <ExcelImport />,
  },

  {
    layout: "/admin",
    path: "category/:categoryId",
    role: ["Admin", "Creator", "Customer"],
    redirect: true,
    component: <Category />,
  },

  {
    name: "ホーム",
    layout: "/admin",
    path: "home",
    isLink: true,
    icon: <span className="icon la la-home text-green-800" />,
    role: ["Admin", "Creator", "Customer"],
    // component: <Home />,
    component: <NewHome />,
  },
  {
    name: "トップ画面",
    layout: "/admin",
    path: "dashboard",
    icon: <span className="icon la la-laptop text-blue-700" />,
    role: ["Admin", "Creator", "Customer"],
  },
  {
    name: "ファイルアップロード",
    layout: "/admin",
    path: "documents",
    isLink: true,
    icon: <span className="icon la la-file-alt text-indigo-900" />,
    role: ["Admin", "Creator"],
    component: <Documents />,
  },
  {
    name: "設定",
    layout: "/admin",
    path: "settings",
    icon: <span className="icon la la-cog text-red-600" />,
    role: ["Admin", "Creator"],
  },

  // Settings routes
  {
    name: "情報",
    layout: "/admin",
    path: "information",
    icon: <span className="la la-info-circle" />,
    role: ["Admin"],
    parent: "settings",
    component: <Information />,
  },
  {
    name: "ユーザー",
    layout: "/admin",
    path: "users",
    icon: <span className="la la-user-circle" />,
    role: ["Admin"],
    parent: "settings",
    component: <Users />,
  },
  {
    name: "お知らせ",
    layout: "/admin",
    path: "news",
    icon: <span className="la la-list" />,
    role: ["Admin"],
    parent: "settings",
    component: <News />,
  },
  {
    name: "カテゴリー",
    layout: "/admin",
    path: "categories",
    icon: <span className="la la-tags" />,
    role: ["Admin", "Creator"],
    parent: "settings",
    component: <Categories />,
  },
  {
    name: "マンション",
    layout: "/admin",
    path: "apartments",
    icon: <span className="la la-building" />,
    role: ["Admin"],
    parent: "settings",
    component: <Apartments />,
  },

  {
    name: "メール登録",
    layout: "/admin",
    path: "emails",
    icon: <span className="la la-envelope-square" />,
    role: ["Admin"],
    parent: "settings",
    component: <Emails />,
  },

  {
    name: "オプション工事",
    layout: "/admin",
    path: "construction-details",
    icon: <span className="la la-atom" />,
    role: ["Admin", "Creator"],
    parent: "settings",
    component: <ConstructionDetails />,
  },

  // Inquiries
  {
    name: "お問い合わせ",
    layout: "/admin",
    path: "inquiries",
    icon: <span className="icon la la-envelope text-teal-800" />,
    role: ["Admin"],
    isLink: true,
    component: <Inquiries />,
  },

  {
    layout: "/admin",
    path: "inquiry/show/:id",
    redirect: true,
    role: ["Admin", "Creator"],
    component: <InquiryShow />,
  },

  // News
  {
    layout: "/auth",
    path: "news/show/:id",
    redirect: true,
    role: ["Admin", "Creator"],
    component: <NewsShow />,
  },

  // Constructions
  // all construction list
  {
    name: "オプション工事",
    layout: "/admin",
    path: "constructions",
    icon: <span className="icon la la-paperclip text-red-600" />,
    role: ["Admin"],
    isLink: true,
    component: <Constructions />,
  },

  {
    name: "ご挨拶",
    layout: "/admin",
    path: "greetings",
    icon: <span className="icon la la-comment-dots text-yellow-600" />,
    role: ["Admin", "Creator"],
    isLink: true,
    component: <Greetings />,
  },

  // constructions room grid
  // {
  //   name: "オプション工事",
  //   layout: "/admin",
  //   path: "construction-room-grid",
  //   icon: <span className="icon la la-paperclip" />,
  //   role: ["Creator"],
  //   isLink: true,
  //   component: <ConstructionsRoomGrid />,
  // },

  {
    layout: "/admin",
    path: "construction-room-detail/:id/:roomNumber/:username",
    role: ["Creator"],
    redirect: true,
    component: <ConstructionRoomDetail />,
  },

  {
    layout: "/admin",
    path: "own-constructions",
    redirect: true,
    role: ["Customer"],
    component: <OwnConstructions />,
  },

  {
    layout: "/admin",
    path: "own-inquiries",
    redirect: true,
    role: ["Customer"],
    component: <OwnInquiries />,
  },

  // custom constructions
  {
    layout: "/admin",
    path: "construction/custom-detail/:id",
    redirect: true,
    role: ["Admin"],
    component: <ConstructionCustomDetail />,
  },

  {
    layout: "/admin",
    path: "construction/create",
    redirect: true,
    role: ["Customer"],
    component: <ConstructionCreate />,
  },

  {
    layout: "/admin",
    path: "construction/check",
    redirect: true,
    role: ["Customer"],
    component: <ConstructionCheck />,
  },

  // {
  //   layout: "/admin",
  //   path: "construction/confirm/:id",
  //   redirect: true,
  //   role: ["Customer"],
  //   component: <ConstructionConfirm />,
  // },

  {
    layout: "/admin",
    path: "construction/finish/:id",
    redirect: true,
    role: ["Customer"],
    component: <ConstructionFinish />,
  },

  // custom inquiries
  {
    layout: "/admin",
    path: "inquiry/create",
    redirect: true,
    role: ["Customer"],
    component: <InquiryCreateAndUpdate />,
  },
  {
    layout: "/admin",
    path: "inquiry/update/:id",
    redirect: true,
    role: ["Customer"],
    component: <InquiryCreateAndUpdate />,
  },
  {
    layout: "/admin",
    path: "inquiry/finish",
    redirect: true,
    role: ["Customer"],
    component: <InquiryFinish />,
  },
];
export default routes;
