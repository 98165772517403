import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";

import { toggleMenu } from "reducers/actions";
import { Link, NavLink } from "react-router-dom";
import Tippy, { useSingleton } from "@tippyjs/react";

import Backdrop from "components/Backdrop";

import useOnClickOutside from "reducers/hooks/useOnClickOutside";
import useBrandedMenu from "reducers/hooks/useBrandedMenu";
import useMenuType from "reducers/hooks/useMenuType";
import useWindowSize from "reducers/hooks/useWindowSize";

import useConstructionCount from "reducers/hooks/useConstructionCount";
import useInquiryCount from "reducers/hooks/useInquiryCount";
import useCategoryCount from "reducers/hooks/useCategoryCount";

import routes from "routes.js";

import { useAuth } from "context/auth";

import server from "helper/server";

const MenuBarContext = createContext();

const MenuBar = () => {
  const { userData } = useAuth();
  const role = userData ? userData.role : null;

  const dispatch = useDispatch();

  const menuBarVisible = useSelector((state) => state.root.menuBarVisible);
  const categoryCount = useSelector((state) => state.categories.categoryCount);
  const constructionCount = useSelector(
    (state) => state.constructions.constructionCount
  );
  const inquiryCount = useSelector((state) => state.inquiries.inquiryCount);

  const [brandedMenu] = useBrandedMenu();
  const [menuType] = useMenuType();
  const windowSize = useWindowSize();

  const getCategoryCount = useCategoryCount();
  const getConstructionCount = useConstructionCount();
  const getInquiryCount = useInquiryCount();

  const [menuLoading, setMenuLoading] = useState(false);
  const [menuList, setMenuList] = useState([]);
  const [activeMenus, setActiveMenus] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isBackdropActive, setIsBackdropActive] = useState(false);
  const [isTooltipDisabled, setIsTooltipDisabled] = useState(false);

  const menuBar = useRef(null);

  const [source, target] = useSingleton();

  // category list
  const getCategoryList = async () => {
    setMenuLoading(true);

    const response = await server.call({ url: "/categories/menu-list" });
    // get count
    if (response.data && response.data.success && response.data.data) {
      setMenuList(response.data.data.rows);
      await getCategoryCount();
    }

    setMenuLoading(false);
  };

  // Activate Menu
  const activateMenu = (menuName, withMenuDetail = true) => {
    if (menuType !== "wide") {
      if (activeMenus.includes(menuName) && isMenuOpen) {
        hideMenuDetail();
      } else {
        setActiveMenus([menuName]);
        localStorage.setItem("ActiveMenus", [menuName]);

        if (withMenuDetail) {
          if (menuName === "dashboard") {
            role !== "Customer" && getCategoryList();
          }
          setIsMenuOpen(true);
          setIsBackdropActive(true);
        } else {
          hideMenuDetail();
        }
      }
    } else {
      if (activeMenus.includes(menuName)) {
        setActiveMenus([]);
        localStorage.setItem("ActiveMenus", []);
      } else {
        setActiveMenus([menuName]);
        localStorage.setItem("ActiveMenus", [menuName]);
      }
    }
  };

  // Check if a Menu is Active
  const isActive = (menuName) => {
    return activeMenus.includes(menuName);
  };

  // Check if a Menu Detail is Open
  const isOpen = (menuName) => {
    return activeMenus.includes(menuName) && isMenuOpen;
  };

  // Hide Menu Detail
  const hideMenuDetail = () => {
    setIsMenuOpen(false);
    setIsBackdropActive(false);
    toggleMenuHide();
  };

  const toggleMenuHide = () => {
    if (windowSize.width < 767) {
      dispatch(toggleMenu(false));
    }
  };

  // Hide Menu Detail - When clicked elsewhere
  useOnClickOutside(menuBar, () => {
    if (menuType !== "wide") {
      hideMenuDetail();
      toggleMenuHide();
    }
  });

  useEffect(() => {
    activateMenu(localStorage.getItem("ActiveMenus"), false);
    role === "Customer" && getCategoryList();
    role === "Creator" && getConstructionCount();
    role === "Creator" && getInquiryCount();
  }, []);

  useEffect(() => {
    if (menuType === "icon-only") {
      setIsTooltipDisabled(true);
    }
  }, [menuType]);

  useEffect(() => {
    if (windowSize.width > 767) {
      dispatch(toggleMenu(true));
    } else {
      dispatch(toggleMenu(false));
    }
  }, [windowSize]);

  return (
    <MenuBarContext.Provider
      value={{
        role,
        menuLoading,
        menuList,
        categoryCount,
        constructionCount,
        inquiryCount,
        isOpen,
        hideMenuDetail,
        activateMenu,
      }}
    >
      <Backdrop active={isBackdropActive} workspaceOnly={true} />
      <Tippy
        singleton={source}
        touch={["hold", 500]}
        theme="light-border tooltip"
        offset={[0, 12]}
        animation="scale"
        placement="right"
        appendTo={document.body}
        disabled={isTooltipDisabled}
      />

      <aside
        ref={menuBar}
        className={classNames(
          "menu-bar",
          "menu-sticky",
          { "menu-hidden": !menuBarVisible },
          { menu_branded: brandedMenu },
          "menu-default"
        )}
      >
        {["Admin", "Creator"].includes(role) && (
          <>
            <div className="menu-items">
              {Array.isArray(routes) &&
                routes
                  .filter((s) => !s.redirect)
                  .map((route, key) => {
                    if (
                      route.role &&
                      route.role.includes(userData.role) &&
                      !route.parent
                    ) {
                      if (route.isLink) {
                        return (
                          <Tippy
                            key={"Tippy-" + key}
                            content={route.name}
                            singleton={target}
                          >
                            <Link
                              to={route.layout + "/" + route.path}
                              onClick={() => activateMenu(route.path, false)}
                              className={classNames("link", {
                                active: isActive(route.path),
                              })}
                            >
                              {route.icon}
                              <span className="title">{route.name}</span>
                            </Link>
                          </Tippy>
                        );
                      } else {
                        return (
                          <Tippy
                            key={"Tippy-" + key}
                            content={route.name}
                            singleton={target}
                          >
                            <button
                              onClick={() => activateMenu(route.path)}
                              className={classNames("link", {
                                active: isActive(route.path),
                              })}
                            >
                              {route.icon}
                              <span className="title">{route.name}</span>
                            </button>
                          </Tippy>
                        );
                      }
                    }
                  })}

              {/* Construction */}
              {role === "Creator" && (
                <Tippy content={"オプション工事"} singleton={target}>
                  <Link
                    to={"/admin/construction-room-grid"}
                    onClick={() =>
                      activateMenu("construction-room-grid", false)
                    }
                    className={classNames("link relative", {
                      active: isActive("construction-room-grid"),
                    })}
                  >
                    <span className="icon la la-paperclip text-red-600" />
                    <span className="title">オプション工事</span>
                    <div className="absolute top-1/3 -translate-y-1/2 left-auto bottom-auto right-5 flex flex-row justify-between items-center">
                      {constructionCount > 0 && (
                        <div className="count">{constructionCount}</div>
                      )}
                    </div>
                  </Link>
                </Tippy>
              )}

              {/* Inquiry */}
              {role === "Creator" && (
                <Tippy content={"お問い合わせ"} singleton={target}>
                  <Link
                    to={"/admin/inquiries"}
                    onClick={() => activateMenu("inquiries", false)}
                    className={classNames("link relative", {
                      active: isActive("inquiries"),
                    })}
                  >
                    <span className="icon la la-envelope text-teal-800" />
                    <span className="title">お問い合わせ</span>
                    <div className="absolute top-1/3 -translate-y-1/2 left-auto bottom-auto right-5 flex flex-row justify-between items-center">
                      {inquiryCount > 0 && (
                        <div className="count">{inquiryCount}</div>
                      )}
                    </div>
                  </Link>
                </Tippy>
              )}
            </div>
            {menuType !== "wide" && (
              <>
                <MenuCategories />
                <MenuSettings />
              </>
            )}
          </>
        )}

        {["Customer"].includes(role) && <MenuCustomers />}
      </aside>
    </MenuBarContext.Provider>
  );
};

export default MenuBar;

const MenuCategories = () => {
  const {
    role,
    menuLoading,
    menuList,
    categoryCount,
    constructionCount,
    inquiryCount,
    isOpen,
    hideMenuDetail,
  } = useContext(MenuBarContext);

  return (
    <div className={classNames("menu-detail", { open: isOpen("dashboard") })}>
      <div className="menu-detail-wrapper">
        {menuLoading ? (
          <div className="w-full flex justify-center items-center py-2 px-4">
            <span
              className={
                "las la-spinner animate-spin text-3xl text-gray-600 dark:text-gray-200"
              }
            />
          </div>
        ) : (
          <div>
            {menuList.length > 0 && (
              <div className="flex justify-center items-center py-3 border-b border-solid dark:border-gray-600">
                <span className="uppercase font-semibold">[お知らせ]</span>
              </div>
            )}
            {menuList.map((menu, key) => {
              let count = 0;
              let findCategory = categoryCount.filter(
                (s) => s.category_id === menu.id
              );
              findCategory = findCategory.length > 0 ? findCategory[0] : null;
              count = findCategory ? findCategory.count : 0;

              return (
                <NavLink
                  key={"NavLink-" + key}
                  to={"/admin/category/" + menu.id}
                  onClick={hideMenuDetail}
                  className="flex flex-row justify-between"
                >
                  {menu.category_name}
                  {count > 0 && <div className="count">{count}</div>}
                </NavLink>
              );
            })}

            {/* Inquiry */}
            {role !== "Admin" && (
              <>
                <div className="flex justify-center items-center py-3 border-b border-solid dark:border-gray-600">
                  <span className="uppercase font-semibold">
                    [お問い合わせ]
                  </span>
                </div>
                <Link
                  onClick={hideMenuDetail}
                  to={"/admin/inquiries"}
                  className="flex justify-between items-center px-5 py-4 whitespace-nowrap border-solid border-b border-gray-200 dark:border-gray-900 font-medium"
                >
                  <div className="flex flex-row justify-between items-center">
                    <div>お問い合わせ</div>
                    {inquiryCount > 0 && (
                      <div className="count">{inquiryCount}</div>
                    )}
                  </div>
                </Link>
              </>
            )}

            {/* Construction */}
            {role !== "Admin" && (
              <>
                <div className="flex justify-center items-center py-3 border-b border-solid dark:border-gray-600">
                  <span className="uppercase font-semibold">[申し込み]</span>
                </div>
                <Link
                  onClick={hideMenuDetail}
                  to={"/admin/construction-room-grid"}
                  className="flex justify-between items-center px-5 py-4 whitespace-nowrap border-solid border-b border-gray-200 dark:border-gray-900 font-medium"
                >
                  <div className="flex flex-row justify-between items-center">
                    <div>オプション工事</div>
                    {constructionCount > 0 && (
                      <div className="count">{constructionCount}</div>
                    )}
                  </div>
                </Link>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const MenuSettings = () => {
  const { userData } = useAuth();
  const { isOpen, hideMenuDetail } = useContext(MenuBarContext);

  return (
    <div className={classNames("menu-detail", { open: isOpen("settings") })}>
      <div className="menu-detail-wrapper">
        {Array.isArray(routes) &&
          routes
            .filter((s) => !s.redirect && s.parent === "settings")
            .map(
              (route, key) =>
                route.role &&
                route.role.includes(userData.role) && (
                  <NavLink
                    key={"NavLink-" + key}
                    to={route.layout + "/" + route.path}
                    onClick={hideMenuDetail}
                  >
                    {route.icon}
                    {route.name}
                  </NavLink>
                )
            )}
      </div>
    </div>
  );
};

const MenuCustomers = () => {
  const { menuLoading, menuList, categoryCount, hideMenuDetail } =
    useContext(MenuBarContext);
  const windowSize = useWindowSize();

  return (
    <div className="bg-white dark:bg-gray-900">
      <div
        className={classNames(
          "p-0 bg-foreground overflow-y-scroll w-[200px] h-screen",
          { open: true }
        )}
      >
        {menuLoading ? (
          <div className="w-full flex justify-center items-center py-2 px-4">
            <span
              className={
                "las la-spinner animate-spin text-3xl text-gray-600 dark:text-gray-200"
              }
            />
          </div>
        ) : (
          <div>
            {menuList.length > 0 && (
              <div className="flex justify-center items-center py-3 border-b border-solid dark:border-gray-700">
                <span className="uppercase font-semibold">[お知らせ]</span>
              </div>
            )}
            {menuList &&
              menuList.map((menu, key) => {
                let count = 0;
                let findCategory = categoryCount.filter(
                  (s) => s.category_id === menu.id
                );
                findCategory = findCategory.length > 0 ? findCategory[0] : null;
                count = findCategory ? findCategory.count : 0;

                return (
                  <Link
                    key={"Link-" + key}
                    onClick={() => {
                      if (windowSize.width < 767) {
                        hideMenuDetail();
                      }
                    }}
                    to={"/admin/category/" + menu.id}
                    className="flex justify-between items-center px-5 py-4 text-[1.2em] text-primary whitespace-nowrap border-solid border-b border-gray-200 dark:border-gray-900 font-medium"
                  >
                    <div>{menu.category_name}</div>
                    {count > 0 && (
                      <div className="py-0.5 px-1.5 border border-red-600 text-red-600 text-xs rounded-full ml-4">
                        {count}
                      </div>
                    )}
                  </Link>
                );
              })}

            {/* Inquiries */}
            {/* <div className="flex justify-center items-center py-3 border-b border-solid dark:border-gray-600">
              <span className="uppercase font-semibold">[お問い合わせ]</span>
            </div>
            <Link
              onClick={() => {
                if (windowSize.width < 767) {
                  hideMenuDetail();
                }
              }}
              to={"/admin/own-inquiries"}
              className="flex justify-between items-center px-5 py-4 text-[1.2em] text-primary whitespace-nowrap border-solid border-b border-gray-200 dark:border-gray-900 font-medium"
            >
              <div>お問い合わせ</div>
            </Link> */}

            {/* Constructions */}
            <div className="flex justify-center items-center py-3 border-b border-solid dark:border-gray-600">
              <span className="uppercase font-semibold">[申し込み]</span>
            </div>
            <Link
              onClick={() => {
                if (windowSize.width < 767) {
                  hideMenuDetail();
                }
              }}
              to={"/admin/own-constructions"}
              className="flex justify-between items-center px-5 py-4 text-[1.2em] text-primary whitespace-nowrap border-solid border-b border-gray-200 dark:border-gray-900 font-medium"
            >
              <div>オプション工事</div>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};
