import { useNavigate, useParams } from "react-router-dom";

import { useEffect } from "react";

const InquiryFinishPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/admin/home");
    }, 3000);
  }, [id]);

  return (
    <div className="bg-gray-200 w-full min-h-[500px] flex flex-row justify-center items-center">
      <p className="inline-block text-center text-3xl font-light">
        お問い合わせを受け付けました。
        <br />
        <br />
        ご連絡ありがとうございました。
      </p>
    </div>
  );
};

export default InquiryFinishPage;
