import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import Button from "components/Button";
import {
  setAlert,
  setShowPdfDialog,
  setPdfSrc,
  setShowImageDialog,
  setImageSrc,
} from "reducers/actions";

import FileItem from "customComponents/Inquiries/FileUpload/FileItem";

import server from "helper/server";
import helper from "helper/helper";
import fileHelper from "helper/fileHelper";

// const extensions = ["png", "jpg", "jpeg", "gif", "webp", "pdf"];

const FileUpload = (props) => {
  const dispatch = useDispatch();
  const { id, name, multiple = false, readOnly = false } = props;

  const [value, setValue] = useState([]);
  const [fileSize, setFileSize] = useState(0);
  // refs
  const FileInput = useRef();

  useEffect(() => {
    getData(id);
  }, [id]);

  const chooseFile = async (e) => {
    e.preventDefault();
    const files = await e.target.files;

    let newValue = value;
    let tempFileSize = fileSize;

    if (multiple === false) {
      newValue = [];
    }

    let fileCount =
      newValue.length > 0 && newValue[newValue.length - 1]
        ? newValue[newValue.length - 1].index + 1
        : 0;

    const file = files[0];

    let fileSrc = null;
    if (file.type.indexOf("image") > -1) {
      fileSrc = await fileHelper.getFileSrc(file);
    }

    if (file.size > 3145728) {
      dispatch(
        setAlert({
          show: true,
          success: false,
          message:
            "アップロードするファイルの合計サイズは 3MB を超えてはなりません",
        })
      );
    }

    if (file.name.length < 245) {
      const newValueItem = {
        index: fileCount,
        file: file,
        fileSrc: fileSrc,
        fileInfo: {
          id: null,
          link: null,
          field_name: name,
          generated_name: null,
          original_name: file.name,
          size: file.size,
          extension: file.type,
        },
      };
      tempFileSize += parseInt(file.size);
      if (tempFileSize > 104857600) {
        tempFileSize -= parseInt(file.size);
        dispatch(
          setAlert({
            show: true,
            success: false,
            message:
              "アップロードするファイルの合計サイズは 300MB を超えてはなりません",
          })
        );
      } else {
        setFileSize(tempFileSize);
        newValue.push(newValueItem);
      }
    } else {
      dispatch(
        setAlert({
          show: true,
          success: false,
          message: "入力したファイル名が長すぎます",
        })
      );
    }

    changeValue(newValue);
    setValue(newValue);
    setFileSize(tempFileSize);

    e.target.value = "";
    FileInput.current.value = "";
  };

  const remove = (index) => {
    let oldFileSize = fileSize;
    let newValue = value;
    let removeValue = newValue.filter((s) => s.index + "" === index + "");
    newValue = newValue.filter((s) => s.index + "" !== index + "");
    let newFileSize =
      removeValue && removeValue[0].fileInfo
        ? parseInt(oldFileSize) - parseInt(removeValue[0].fileInfo.fileSize)
        : oldFileSize;
    changeValue(newValue);
    setValue(newValue);
    setFileSize(!isNaN(newFileSize) ? parseInt(newFileSize) : 0);
  };

  const changeValue = (value) => {
    props.changeValue && props.changeValue(name, value);
  };

  const getData = async (id) => {
    let newValue = [];
    let tempFileSize = 0;
    let fileCount = 0;
    if (id) {
      const response = await server.baseList({
        objectName: "allFiles",
        params: `?select=["*"]&filter=${JSON.stringify([
          ["object_name", "=", "inquiries"],
          "and",
          ["field_name", "=", "files"],
          "and",
          ["data_id", "=", id + ""],
          "and",
          ["status", "=", "active"],
        ])}`,
      });
      if (
        response.data &&
        response.data.data &&
        Array.isArray(response.data.data.rows)
      ) {
        response.data.data.rows.forEach((element) => {
          if (element.status === "active") {
            newValue.push({
              index: fileCount,
              file: null,
              fileSrc: null,
              fileInfo: {
                id: element.id,
                field_name: element.field_name,
                link: server.fileURL + "/inquiries/" + element.generated_name,
                original_name: element.original_name,
                generated_name: element.generated_name,
                size: element.size,
                extension: element.extension,
              },
            });
            tempFileSize += element.size;
            fileCount++;
          }
        });
      }
    }
    setValue(newValue);
    setFileSize(tempFileSize);
  };

  const show = async (index) => {
    const fileArray = value.filter((s) => s.index === index);
    const fileInfo = fileArray.length === 1 ? fileArray[0].fileInfo : null;
    const file = fileArray.length === 1 ? fileArray[0].file : null;

    let src = null;
    if (!file && fileInfo && fileInfo.link) {
      if (helper.urlExists(fileInfo.link)) {
        if (fileInfo.extension === "pdf") {
          showPdf(fileInfo.link);
        } else {
          showImage(fileInfo.link);
        }
      } else {
        dispatch(
          setAlert({
            show: true,
            success: false,
            message: "ファイルが見つかりません",
          })
        );
      }
    } else {
      src = file ? await fileHelper.getFileSrc(file) : null;
      if (file && file.type.indexOf("pdf") > -1) {
        showPdf(src);
      } else {
        showImage(src);
      }
    }

    // if (src && typeof src === "string") {
    //   showPdf(src);
    // }
  };

  const showPdf = async (src) => {
    dispatch(setShowPdfDialog(true));
    dispatch(setPdfSrc(src));
  };

  const showImage = async (src) => {
    dispatch(setShowImageDialog(true));
    dispatch(setImageSrc(src));
  };

  return (
    <div className="mt-3">
      <div className="flex flex-wrap w-full">
        {value.map((file, index) => {
          return (
            <div
              className="w-full mt-0 mb-4 my-1 py-0 px-1 border-l-2 border-solid border-[#ccc]"
              key={index}
            >
              <FileItem
                remove={remove}
                show={show}
                index={file.index}
                fileInfo={file.fileInfo}
                readOnly={readOnly}
              />
            </div>
          );
        })}
      </div>

      {!readOnly && (
        <div className="mb-4">
          <span className="text-base max-sm:text-sm">
            ファイル添付（3MB以内、jpg,ipeg,gif,png,pdf形式が送信可能です）
          </span>
        </div>
      )}

      {!readOnly && value.length < 3 && (
        <Button
          type="button"
          disabled={readOnly}
          className="uppercase rounded-none px-3 py-1"
          onClick={() => FileInput.current.click && FileInput.current.click()}
          color={"danger"}
          outlined={true}
        >
          <span className="la la-file text-base leading-none mr-2" />
          <span className="text-sm">ファイルを選ぶ</span>
        </Button>
      )}
      <input
        ref={FileInput}
        name={name}
        type="file"
        multiple={multiple}
        // accept={
        //   "image/jpg,image/jpeg,image/gif,image/png,image/webp,application/pdf"
        // }
        accept=".gif,.jpg,.jpeg,.png,.webp,.pdf"
        onChange={chooseFile}
        className="hidden"
      />
    </div>
  );
};

export default FileUpload;
