const moment = require("moment");

const isNotEmpty = (value) => {
  return value !== undefined && value !== null && value !== "";
};

module.exports.sleep = async (ms = 5000) => {
  new Promise((resolve) => setTimeout(resolve, ms));
};

module.exports.getRequest = (options, rootFilter, rootSort) => {
  process.env.NODE_ENV === "development" &&
    console.log({ rootFilter, rootSort, options });
  var option;
  let params = "?";
  ["skip", "take", "sort", "filter"].forEach((i) => {
    if (i in options && isNotEmpty(options[i])) {
      option = options[i];
      if (i === "filter" && rootFilter) {
        if (Array.isArray(option) && option.length > 0) {
          option = [[option], "and", rootFilter];
        } else {
          option = rootFilter;
        }
      }
      params += `${i}=${JSON.stringify(option)}&`;
    }
  });
  if (!isNotEmpty(options["sort"]) && rootSort) {
    option = rootSort;
    params += `sort=${JSON.stringify(option)}&`;
  }
  if (!isNotEmpty(options["filter"]) && rootFilter) {
    option = rootFilter;
    params += `filter=${JSON.stringify(option)}&`;
  }

  return params;
};

module.exports.getBuhel = (value) => {
  try {
    var result = parseInt(parseFloat("" + value));
    if (!isNaN(result)) {
      var res = this.getFloat(value);
      if (res > result) {
        if (this.getFloat(result) + this.getFloat("0.5") <= res) {
          return result + 1;
        } else {
          return result;
        }
      }
      return result;
    } else {
      return 0;
    }
  } catch (ex) {
    process.env.NODE_ENV === "development" && console.log(ex);
  }
};

module.exports.getFloat = (value) => {
  try {
    var result = parseFloat(value);
    if (!isNaN(result)) {
      return result;
    } else {
      return 0;
    }
  } catch (ex) {
    process.env.NODE_ENV === "development" && console.log(ex);
  }
};

module.exports.getValue = (object, fieldName) => {
  try {
    var names = fieldName.split(".");
    var valueObject = object;
    for (var i = 0; i < names.length; i++) {
      valueObject = valueObject[names[i]];
    }

    return valueObject;
  } catch (ex) {
    return null;
  }
};

module.exports.convertToHalf = (value) => {
  return value.replace(/[！-～]/g, function (e) {
    String.fromCharCode(e.charCodeAt(0) - 0xfee0);
  });
  // return value.replace(/[\uFF10-\uFF19]/g, function (m) {
  //   return String.fromCharCode(m.charCodeAt(0) - 0xfee0);
  // });
};

module.exports.convertToFull = (value) => {
  return value.replace(/[!-~]/g, function (e) {
    String.fromCharCode(e.charCodeAt(0) + 0xfee0);
  });
};

module.exports.setJapanDate = (date) => {
  const tempDate = !isNaN(moment(date)) ? date : moment().format();
  let dateStr = "";
  const year = moment(tempDate).format("YYYY");
  const month = moment(tempDate).format("MM");
  const day = moment(tempDate).format("DD");
  dateStr = year + "年" + month + "月" + day + "日";
  return dateStr;
};

module.exports.urlExists = (url) => {
  if (url) {
    const http = new XMLHttpRequest();
    http.open("HEAD", url, false);
    http.send();
    return http.status != 404;
  } else {
    return false;
  }
};

module.exports.typeColor = (type) => {
  let color = "#6e6e6e";
  if (type === "お知らせ") {
    color = "orange";
  } else if (type === "ニュース") {
    color = "green";
  } else if (type === "工事報告") {
    color = "blue";
  } else {
    color = "#6e6e6e";
  }

  return color;
};
