import React, { useEffect, useState } from "react";
import classNames from "classnames";

import Button from "components/Button";
import Modal, { ModalBody, ModalFooter, ModalHeader } from "components/Modal";

import { BaseLoadButton } from "baseComponents";

const Dialog = (props) => {
  const {
    title,
    width,
    hide,
    save,
    hideSave,
    saveIcon = null,
    isActive = false,
    children,
  } = props;

  const widthClass = width ? width : "w-1/2";

  return (
    <Modal
      active={isActive}
      confirm={true}
      staticBackdrop
      onClose={() => hide && hide()}
    >
      <ModalHeader>{title ? title : null}</ModalHeader>
      <ModalBody className="mx-3 py-3">
        <div className={classNames(widthClass, "max-md:w-11/12", "h-full")}>
          {children}
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="flex justify-between w-full">
          <Button
            color="danger"
            className="uppercase"
            onClick={() => hide && hide()}
          >
            閉じる
          </Button>
          {!hideSave && (
            <BaseLoadButton
              icon={saveIcon}
              className="uppercase"
              onClick={() => save && save()}
            >
              保存
            </BaseLoadButton>
          )}
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default Dialog;
