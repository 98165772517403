const AddCard = (props) => {
  const { onClick } = props;
  return (
    <div
      className="cursor-pointer rounded-md bg-gray-200 dark:bg-gray-600 dark:hover:bg-gray-700 hover:bg-gray-300 flex flex-row justify-center items-center py-8 px-6 shadow-md"
      onClick={() => onClick && onClick()}
    >
      <span className="la la-envelope text-6xl text-gray-500 dark:text-gray-800" />
    </div>
  );
};

export default AddCard;
