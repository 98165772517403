import { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import classNames from "classnames";

import Label from "components/form/Label";

import helper from "helper/helper";

const BaseInputMask = (props) => {
  const {
    label,
    helperText,
    changeValue,
    children,
    invalid,
    className,
    name,
    value = "",
    fullWidth = false,
    halfWidth = false,
    mask,
    maskChar = "_",
    // alwaysShowMask = false,
    permanents = [],
    ...rest
  } = props;

  const [currentValue, setCurrentValue] = useState("");

  const formatChars = {
    9: "[0-9]",
    a: "[A-Za-z]",
    "*": "[A-Za-z0-9]",
  };

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <div className="mb-3">
      {label && <Label htmlFor="input">{label}</Label>}
      <label className="form-control-addon-within">
        <InputMask
          className={classNames(
            "form-control",
            "text-base",
            "font-light",
            { "is-invalid": invalid },
            className
          )}
          onChange={(event) => {
            let value = event.target.value;
            if (fullWidth) value = helper.convertToFull(value);
            if (halfWidth) value = helper.convertToHalf(value);
            setCurrentValue(value);
            changeValue && changeValue(name, value);
          }}
          value={currentValue}
          mask={mask}
          maskChar={maskChar}
          formatChars={formatChars}
          permanents={permanents}
          {...rest}
        />
      </label>
      {helperText && <small className="block mt-2">{helperText}</small>}
    </div>
  );
};

export default BaseInputMask;
