import { BaseCard } from "baseComponents";
import { News } from "customComponents";

const NewsPage = () => {
  return (
    <BaseCard title={"お知らせ"}>
      <News />
    </BaseCard>
  );
};

export default NewsPage;
