import { useEffect, useState } from "react";

import moment from "moment";
import classNames from "classnames";

import Label from "components/form/Label";

import { years, months, days } from "assets/data/dates";

const BaseSelectDate = (props) => {
  const { label, changeValue, value, invalid, className, ...rest } = props;

  const [year, setYear] = useState(null);
  const [month, setMonth] = useState(null);
  const [day, setDay] = useState(null);

  useEffect(() => {
    convertValue(value);
  }, [value]);

  const convertValue = (value) => {
    if (value && typeof value === "string") {
      const chars = value.split("-");
      if (chars.length === 3) {
        setYear(chars[0]);
        setMonth(chars[1]);
        setDay(chars[2]);
      }
    } else {
      setYear(null);
      setMonth(null);
      setDay(null);
    }
  };

  const onChange = (field, value) => {
    let dateStr = "";
    let tempYear = year ? year : moment().format("YYYY");
    let tempMonth = month ? month : pad2(moment().format("MM"));
    let tempDay = day ? day : pad2(moment().format("DD"));
    if (field === "year") {
      tempYear = value;
      setYear(value);
    }
    if (field === "month") {
      tempMonth = value;
      setMonth(value);
    }
    if (field === "day") {
      tempDay = value;
      setDay(value);
    }
    dateStr = tempYear + "-" + tempMonth + "-" + tempDay;

    changeValue && changeValue(props.name, dateStr);
  };

  const pad2 = (value) => {
    return (parseInt(value) < 10 ? "0" : "") + value;
  };

  return (
    <div className="mb-3">
      {label && <Label htmlFor="input">{label}</Label>}
      <div className=" flex flex-row justify-stretch items-center">
        <div className="custom-select inline-block">
          {/* Year */}
          <select
            className={classNames(
              "form-control",
              "border-none",
              { "is-invalid": invalid },
              className
            )}
            value={year}
            onChange={(event) => onChange("year", event.target.value)}
            {...rest}
          >
            <option value={null}>年</option>
            {years.map((e, key) => (
              <option key={key} value={e}>
                {e}
              </option>
            ))}
          </select>
        </div>
        <span className="mx-1 text-xl font-bold">-</span>
        <div className="custom-select inline-block">
          {/* Month */}
          <select
            className={classNames(
              "form-control",
              "border-none",
              { "is-invalid": invalid },
              className
            )}
            value={month}
            onChange={(event) => onChange("month", event.target.value)}
            {...rest}
          >
            <option value={null}>月</option>
            {months.map((e, key) => (
              <option key={key} value={e}>
                {e}
              </option>
            ))}
          </select>
        </div>
        <span className="mx-1 text-xl font-bold">-</span>
        <div className="custom-select inline-block">
          {/* Day */}
          <select
            className={classNames(
              "form-control",
              "border-none",
              { "is-invalid": invalid },
              className
            )}
            value={day}
            onChange={(event) => onChange("day", event.target.value)}
            {...rest}
          >
            <option value={null}>日</option>
            {days.map((e, key) => (
              <option key={key} value={e}>
                {e}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default BaseSelectDate;
