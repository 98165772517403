import React from "react";
import { useSelector } from "react-redux";

import {
  BaseSelectBox,
  BaseSearchSelect,
  BaseInput,
  BaseNumber,
  BaseInputPassword,
} from "baseComponents";

// import ProfilePicture from "customComponents/Users/ProfilePicture";

const Form = (props) => {
  const usersEditData = useSelector((state) => state.users.usersEditData);
  const usersModifyData = useSelector((state) => state.users.usersModifyData);
  const { changeModifyObject } = props;

  const changeValue = (name, value) => {
    changeModifyObject && changeModifyObject({ [name]: value });
  };

  return (
    <>
      {/* <div className="grid grid-cols-1 w-full justify-center mb-8">
        <ProfilePicture
          id={usersEditData.id || null}
          name={"profile"}
          changeValue={changeValue}
        />
      </div> */}
      <div className="grid grid-cols-2 max-sm:grid-cols-1 gap-3">
        <BaseInput
          label={"ユーザー名"}
          name={"username"}
          value={usersEditData.username || null}
          placeholder={"ユーザー名"}
          helperText={"ユーザー名が重複していません"}
          changeValue={changeValue}
        />
        <BaseSelectBox
          label={"役割"}
          name={"role"}
          value={usersEditData.role || null}
          data={[
            { id: "Admin", text: "Admin" },
            { id: "Creator", text: "Creator" },
            { id: "Customer", text: "Customer" },
          ]}
          disabled={usersEditData.id === 1 ? true : false}
          changeValue={changeValue}
        />
      </div>
      <div className="grid grid-cols-2 max-sm:grid-cols-1 gap-3">
        <BaseInput
          label={"氏名(姓)"}
          name={"firstname"}
          value={usersEditData.firstname || null}
          placeholder={"氏名(姓)"}
          changeValue={changeValue}
        />
        <BaseInput
          label={"氏名(名)"}
          name={"lastname"}
          value={usersEditData.lastname || null}
          placeholder={"氏名(名)"}
          changeValue={changeValue}
        />
      </div>
      {/* <div className="grid grid-cols-2 max-sm:grid-cols-1 gap-3">
        <BaseInput
            label={"Email"}
            name={"email"}
            type={"email"}
            value={usersEditData.email || null}
            placeholder={"Email"}
            helperText={"Email is not duplicate"}
            changeValue={changeValue}
          />
      </div> */}
      <div className="grid grid-cols-2 max-sm:grid-cols-1 gap-3">
        <BaseSearchSelect
          label={"マンション"}
          name={"apartment_id"}
          value={usersEditData.apartment_id || null}
          placeholder={"マンション"}
          objectName={"apartments"}
          textField={"apartment_name"}
          changeValue={changeValue}
        />
        {((!usersModifyData.role && usersEditData.role === "Creator") ||
          usersModifyData.role === "Creator") && (
          <BaseInput
            label={"メールアドレス"}
            name={"email"}
            value={usersEditData.email || null}
            placeholder={"メールアドレス"}
            changeValue={changeValue}
          />
        )}
      </div>

      {/* {((!usersModifyData.role && usersEditData.role === "Customer") ||
          usersModifyData.role === "Customer") && (
            <BaseInput
              label={"お部屋番号"}
              name={"room_number"}
              value={usersEditData.room_number || null}
              placeholder={"お部屋番号"}
              changeValue={changeValue}
            />
        )} */}

      {!usersEditData.id && (
        <div className="grid grid-cols-2 max-sm:grid-cols-1 gap-3">
          <BaseInputPassword
            label={"パスワード"}
            placeholder={"パスワード"}
            name={"password"}
            changeValue={changeValue}
          />
          <BaseInputPassword
            label={"パスワード確認"}
            placeholder={"パスワード確認"}
            name={"password_confirm"}
            changeValue={changeValue}
          />
        </div>
      )}
      <div className="h-28"></div>
    </>
  );
};

export default Form;
