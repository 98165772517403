import {
  SHOW_EMAILS_DIALOG,
  EMAILS_MODIFY_DATA,
  EMAILS_EDIT_DATA,
  EMAILS_LIST_DATA,
} from "reducers/actions/emailsTypes";

// Emails Dialogs
export const setEmailsDialog = (isActive) => {
  return { type: SHOW_EMAILS_DIALOG, payload: isActive };
};

// Emails crud
export const setEmailsModifyData = (data) => {
  return { type: EMAILS_MODIFY_DATA, payload: data };
};

export const setEmailsEditData = (data) => {
  return { type: EMAILS_EDIT_DATA, payload: data };
};

export const setEmailsList = (data) => {
  return { type: EMAILS_LIST_DATA, payload: data };
};
