import { useParams } from "react-router-dom";

import CreateAndUpdate from "customComponents/Inquiries/CreateAndUpdate";

const InquiryCreateAndUpdatePage = () => {
  const { id } = useParams();

  return <CreateAndUpdate id={id || null} />;
};

export default InquiryCreateAndUpdatePage;
