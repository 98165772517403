import { useState } from "react";
import { useDispatch } from "react-redux";

import { setAlert } from "reducers/actions";

import { BaseLoadButton } from "baseComponents";

import FileUpload from "customComponents/FileImport/FileUpload";

import server from "helper/server";

const FileImport = (props) => {
  const dispatch = useDispatch();

  const { id } = props;

  const [file, setFile] = useState([]);

  const save = async () => {
    let newAlertData = { show: true, success: true, message: "" };
    if (file.length > 0 && file[0] && file[0].file) {
      const response = await server.baseSaveWithFile({
        url: "/mobile/save",
        data: null,
        files: file,
      });
      if (response.data) {
        newAlertData.success = response.data.success;
        let errMessage = "";
        if (response.data.type && response.data.type === "validation") {
          const errors = response.data.errors;
          if (errors && errors.length > 0) {
            errors.forEach((element) => {
              errMessage += element.message + `, <br />`;
            });
          }
          newAlertData.message = errMessage;
        } else {
          newAlertData.message = response.data.message;
        }
        // if (response.data.success) {
        //   setFile([]);
        // }
      } else {
        newAlertData.success = false;
        newAlertData.message = "エラー";
      }

      dispatch(setAlert(newAlertData));
      return;
    } else {
      newAlertData.success = false;
      newAlertData.message = "ファイルを選ぶ";
      dispatch(setAlert(newAlertData));
      return;
    }
  };

  return (
    <div className="grid grid-cols-1 min-md:grid-cols-2 gap-4">
      <div>
        <div className="grid min-lg:grid-cols-1">
          <FileUpload
            id={id}
            valueFile={file.length > 0 ? file[0] : null}
            changeValue={(value) => setFile(value)}
          />
        </div>
        <div className="grid mt-4 w-full justify-center items-center min-lg:grid-cols-1">
          <BaseLoadButton
            className="w-32 max-sm:p-1 flex justify-center"
            icon="paper-plane"
            outlined={true}
            onClick={async () => await save()}
          >
            <span className="text-lg max-sm:text-sm">Save</span>
          </BaseLoadButton>
        </div>
      </div>
    </div>
  );
};

export default FileImport;
