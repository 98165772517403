import {
  SHOW_GREETINGS_DIALOG,
  GREETINGS_MODIFY_DATA,
  GREETINGS_EDIT_DATA,
  GREETINGS_LIST_DATA,
  GREETINGS_GRID_DATA,
  GREETING_COUNT,
  USERS_FILTER,
} from "reducers/actions/greetingsTypes";

// Greetings Dialogs
export const setGreetingsDialog = (isActive) => {
  return { type: SHOW_GREETINGS_DIALOG, payload: isActive };
};

// Greetings crud
export const setGreetingsModifyData = (data) => {
  return { type: GREETINGS_MODIFY_DATA, payload: data };
};

export const setGreetingsEditData = (data) => {
  return { type: GREETINGS_EDIT_DATA, payload: data };
};

export const setGreetingsList = (data) => {
  return { type: GREETINGS_LIST_DATA, payload: data };
};

export const setGreetingsGrid = (data) => {
  return { type: GREETINGS_GRID_DATA, payload: data };
};

export const setGreetingCount = (count) => {
  return { type: GREETING_COUNT, payload: count };
};

export const setUsersFilter = (filter) => {
  return { type: USERS_FILTER, payload: filter };
};
