import { BaseCard } from "baseComponents";
import { Emails } from "customComponents";

const EmailsPage = () => {
  return (
    <BaseCard title={"メール登録"}>
      <Emails />
    </BaseCard>
  );
};

export default EmailsPage;
