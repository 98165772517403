import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import {
  setShowGridLoading,
  setConstructionsRoomDetailList,
  setAllRoomTotalPrice,
} from "reducers/actions";

import useConstructionCount from "reducers/hooks/useConstructionCount";

import server from "helper/server";
import helper from "helper/helper";

import { useAuth } from "context/auth";

import { BaseGridLoading } from "baseComponents";
import Button from "components/Button";

const RoomDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id, roomNumber, username } = useParams();

  const { userData } = useAuth();
  const role = userData ? userData.role : null;

  const getConstructionCount = useConstructionCount();

  const showGridLoading = useSelector((state) => state.custom.showGridLoading);
  const allRoomTotalPrice = useSelector(
    (state) => state.constructionItems.allRoomTotalPrice
  );
  const constructionsRoomDetailListData = useSelector(
    (state) => state.constructions.constructionsRoomDetailListData
  );

  useEffect(() => {
    getList();
  }, [id]);

  const getList = async () => {
    dispatch(setShowGridLoading(true));
    dispatch(setAllRoomTotalPrice(0));
    dispatch(setConstructionsRoomDetailList([]));

    if (role === "Creator") {
      let rootFilter = [
        ["status", "=", "active"],
        "and",
        ["id", "=", id],
        "and",
        ["room_number", "=", roomNumber],
        "and",
        ["finish_status", "=", "confirmed"],
      ];
      let rootSort = [{ selector: "id", desc: true }];

      let select = JSON.stringify(["id"]);

      let params = helper.getRequest({}, rootFilter, rootSort);
      params += `select=${select}&`;
      params = params.slice(0, -1);

      const response = await server.call({
        url: "/constructions/room-items-list" + params,
        method: "GET",
      });

      if (response.data && response.data.success) {
        await getNotifyRead();
        await getConstructionCount();
        let totalPrice = 0;
        dispatch(setConstructionsRoomDetailList(response.data.data));
        response.data.data.map(
          (item) =>
            (totalPrice += !isNaN(parseFloat(item.total_price))
              ? parseFloat(item.total_price)
              : 0)
        );
        dispatch(setAllRoomTotalPrice(totalPrice));
      }
    }

    dispatch(setShowGridLoading(false));
  };

  const getNotifyRead = async () => {
    await server.call({
      url: "/constructions/room-notify-read",
      data: { id, room_number: roomNumber },
    });
  };

  const savePdf = async () => {
    const input = document.getElementById("roomList");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const imgProps = pdf.getImageProperties(imgData);
      let pdfWidth = pdf.internal.pageSize.getWidth();
      pdfWidth = (pdfWidth / 100) * 98;
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 3, 1, pdfWidth, pdfHeight);
      // pdf.output('dataurlnewwindow');
      pdf.save("roomList.pdf");
    });
  };

  return (
    <div className="w-full relative min-h-[180px]">
      <div id="roomList">
        <div className="mb-2">
          <span className="font-medium text-base">
            {roomNumber} 号室 - {username}
          </span>
        </div>
        <div className="relative min-h-[200px] overflow-x-scroll w-full max-w-full pb-2">
          <div className="max-md:max-w-[80vw] min-w-fit">
            {showGridLoading && <BaseGridLoading />}
            {/* List */}
            <div className="custom-table-head">
              <table className="custom-table">
                <thead>
                  <tr className="h-8">
                    <th className="text-center uppercase w-6/12">工事内容</th>
                    <th className="text-center uppercase w-1/12">数量</th>
                    <th className="text-center uppercase w-1/12">単位</th>
                    <th className="text-center uppercase w-2/12">単価</th>
                    <th className="text-center uppercase w-2/12">合計</th>
                  </tr>
                </thead>
              </table>
            </div>
            <div className="custom-table-body">
              <table className="custom-table">
                <tbody>
                  {constructionsRoomDetailListData.map((item, key) => (
                    <tr key={key}>
                      <td className="text-center w-6/12">{item.detail_name}</td>
                      <td className="text-center w-1/12">{item.quantity}</td>
                      <td className="text-center w-1/12">{item.unity}</td>
                      <td className="text-right w-2/12">{item.unit_price}円</td>
                      <td className="text-right w-2/12">
                        {item.total_price}円
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {constructionsRoomDetailListData.length > 0 && (
              <div className="custom-table-footer">
                <table className="custom-table">
                  <tbody>
                    <tr>
                      <td colSpan={3} className="py-2 pl-4 w-1/2">
                        <div className="flex flex-row justify-start items-start">
                          <span className="font-bold text-sm">
                            合計金額（税抜）
                          </span>
                        </div>
                      </td>
                      <td colSpan={2} className="pr-4 w-1/2">
                        <div className="flex flex-row justify-end items-end">
                          <span className="font-bold text-sm">
                            {allRoomTotalPrice}円
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Buttons */}
      <div className="my-12">
        <div className="grid grid-cols-2 max-sm:grid-cols-1">
          <div className="flex flex-row justify-center">
            <Button outlined={true} color={"info"} onClick={savePdf}>
              印刷する
            </Button>
          </div>
          <div className="flex flex-row justify-center max-sm:mt-4">
            <Button
              outlined={true}
              color={"danger"}
              onClick={() => navigate("/admin/construction-room-grid")}
            >
              戻る
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoomDetail;
