import {
  SHOW_NEWS_DIALOG,
  NEWS_MODIFY_DATA,
  NEWS_EDIT_DATA,
  NEWS_LIST_DATA,
} from "reducers/actions/newsTypes";

// news Dialogs
export const setNewsDialog = (isActive) => {
  return { type: SHOW_NEWS_DIALOG, payload: isActive };
};

// news crud
export const setNewsModifyData = (data) => {
  return { type: NEWS_MODIFY_DATA, payload: data };
};

export const setNewsEditData = (data) => {
  return { type: NEWS_EDIT_DATA, payload: data };
};

export const setNewsList = (data) => {
  return { type: NEWS_LIST_DATA, payload: data };
};
