import React from "react";
import { useSelector } from "react-redux";

import { BaseSelectBox, BaseInput, BaseTextarea } from "baseComponents";

const Form = (props) => {
  const newsEditData = useSelector((state) => state.news.newsEditData);

  const { changeModifyObject } = props;

  const changeValue = (name, value) => {
    changeModifyObject && changeModifyObject({ [name]: value });
  };

  return (
    <>
      <div className="grid grid-cols-1">
        <BaseSelectBox
          label={"タイプ"}
          name={"type"}
          value={newsEditData.type || null}
          data={[
            { id: "お知らせ", text: "お知らせ" }, // orange
            { id: "ニュース", text: "ニュース" }, // success
            { id: "工事報告", text: "工事報告" }, // sky
          ]}
          disabled={newsEditData.id === 1 ? true : false}
          changeValue={changeValue}
        />
      </div>
      <div className="grid grid-cols-1">
        <BaseInput
          label={"タイトル"}
          name={"title"}
          value={newsEditData.title || null}
          placeholder={"タイトル"}
          changeValue={changeValue}
        />
      </div>
      <div className="grid grid-cols-1">
        <BaseInput
          label={"短いコンテンツ"}
          name={"short_content"}
          value={newsEditData.short_content || null}
          placeholder={"短いコンテンツ"}
          changeValue={changeValue}
        />
      </div>
      <div className="grid grid-cols-1">
        <BaseTextarea
          label={"コンテンツ"}
          name={"content"}
          value={newsEditData.content || null}
          placeholder={"コンテンツ"}
          changeValue={changeValue}
          className="h-96"
        />
      </div>
    </>
  );
};

export default Form;
