import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

// redux
import { setAlert } from "reducers/actions";

import { BaseGridLoading } from "baseComponents";

import server from "helper/server";
import helper from "helper/helper";

import { useAuth } from "context/auth";

import defaultImg from "assets/profile-default.png";

const NewTestimonials = () => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userData } = useAuth();
  const role = userData ? userData.role : null;

  const [loading, setLoading] = useState(false);

  const [gridList, setGridList] = useState({ rows: [], count: 0 });

  useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    setLoading(true);

    let rootFilter = ["status", "=", "active"];
    let skip = 0;
    let take = 3;
    let sort = [{ selector: "id", desc: true }];

    let select = JSON.stringify([
      "*",
      { User: ["id", "firstname"] },
      { Apartment: ["id", "apartment_name"] },
      { CreatedUser: ["id", "firstname"] },
      { UpdatedUser: ["id", "firstname"] },
    ]);

    let filter = null;

    // if (role === "Customer") {
    filter =
      role !== "Admin" ? ["apartment_id", "=", userData.apartment_id] : null;
    let params = helper.getRequest(
      { skip, take, sort, filter },
      rootFilter,
      null
    );
    params += `select=${select}&`;
    params = params.slice(0, -1);

    const response = await server.call({
      url: "/greetings/grid-list" + params,
      method: "GET",
    });
    if (response.data && response.data.data) {
      if (response.data.success === false) {
        dispatch(setAlert({ show: true, success: false, message: "間違い" }));
      }
      response.data.success && setGridList(response.data.data);
    }
    // }

    setLoading(false);
  };

  return (
    <>
      <div className="grid grid-cols-3 lg:grid-cols-2 max-md:grid-cols-1 gap-4 my-6">
        {loading ? (
          <div className="relative min-h-96">
            <BaseGridLoading />
          </div>
        ) : (
          <>
            {gridList.rows.length > 0 && (
              <>
                {gridList.rows.map((e, key) => (
                  <div
                    key={"testimonials-" + key}
                    className="relative bg-white dark:bg-gray-600 rounded-xl shadow-sm py-8 px-4"
                  >
                    <div className="flex flex-row max-md:flex-col justify-start h-full">
                      <div className="w-[32%] max-md:w-full flex flex-col justify-center items-center">
                        <img
                          className="w-20 h-20 rounded-full shadow-lg dark:shadow-gray-700 object-cover"
                          src={
                            helper.urlExists(
                              server.fileURL + "/greetings/" + e.generated_name
                            )
                              ? server.fileURL +
                                "/greetings/" +
                                e.generated_name
                              : defaultImg
                          }
                        />
                        <div className="w-full flex flex-col items-center mt-4 justify-start">
                          <span className="font-semibold text-gray-700 dark:text-gray-900">
                            {e.User ? e.User.firstname : null}
                          </span>
                        </div>
                      </div>
                      <div className="w-[68%] max-md:w-full h-full min-h-32 p-2 flex flex-col">
                        <div className="p-4 font-medium h-full w-full flex flex-col items-center justify-center text-justify">
                          <span className="text-gray-700 dark:text-gray-900">
                            {e.comment}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default NewTestimonials;
