import { useEffect, useState } from "react";
import classNames from "classnames";

import helper from "helper/helper";

const Quantity = (props) => {
  const {
    changeValue,
    invalid,
    className,
    value = "",
    disabled = false,
    ...rest
  } = props;

  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <div className="mb-3">
      <input
        type="text"
        className={classNames(
          "form-control",
          "border",
          "border-solid",
          "border-gray-400",
          "text-sm",
          "font-light",
          "py-0.5",
          "px-1",
          "rounded-sm",
          { "border-dashed": disabled },
          { "is-invalid": invalid },
          className
        )}
        onFocus={() =>
          (isNaN(parseFloat(value)) || value === 0) && setCurrentValue("")
        }
        onChange={(event) => {
          let value = event.target.value;
          if (value) {
            value = parseFloat(helper.convertToHalf(value));
            if (!isNaN(value)) {
              if (value <= 0) value = "";
            } else {
              value = "";
            }
          } else {
            value = "";
          }
          setCurrentValue(value);
          changeValue && changeValue(value);
        }}
        value={currentValue}
        disabled={disabled}
        {...rest}
      />
    </div>
  );
};

export default Quantity;
