import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// redux
import { setInquiriesModifyData, setInquiriesEditData } from "reducers/actions";

import useInquiriesSave from "reducers/hooks/useInquiriesSave";
import useInquiryCount from "reducers/hooks/useInquiryCount";

import Button from "components/Button";
import {
  BaseCard,
  BaseLoadButton,
  BaseSearchSelect,
  BaseInput,
  BaseTextarea,
  BaseSelectDate,
  BaseNumber,
} from "baseComponents";
import { BackToHome } from "additionalComponents";
import FileUpload from "customComponents/Inquiries/FileUpload";

import { useAuth } from "context/auth";

import server from "helper/server";

const CreateAndUpdate = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userData } = useAuth();
  const role = userData ? userData.role : null;

  const { id, show = false, readOnly = false } = props;

  useEffect(() => {
    dispatch(setInquiriesModifyData({}));
    dispatch(setInquiriesEditData({}));
    getDetail(id);
  }, [id]);

  const getInquiryCount = useInquiryCount();

  const inquiriesEditData = useSelector(
    (state) => state.inquiries.inquiriesEditData
  );
  const inquiriesModifyData = useSelector(
    (state) => state.inquiries.inquiriesModifyData
  );

  const [saveInquiries] = useInquiriesSave();

  // custom functions
  const getDetail = async (id) => {
    if (id) {
      const response = await server.baseShow({
        objectName: "inquiries",
        id,
      });
      if (response.data.success && response.data.data) {
        dispatch(setInquiriesEditData(response.data.data));
      }

      if (show) {
        await server.call({ url: "/inquiries/notify-read", data: { id } });
        await getInquiryCount();
      }
    } else {
      dispatch(setInquiriesModifyData({}));
      dispatch(setInquiriesEditData({}));
    }
  };

  const changeValue = (name, value) => {
    dispatch(setInquiriesModifyData({ ...inquiriesModifyData, [name]: value }));
  };

  const save = async () => {
    await saveInquiries(inquiriesModifyData, inquiriesEditData, () => {
      dispatch(setInquiriesModifyData({}));
      dispatch(setInquiriesEditData({}));
      navigate("/admin/inquiry/finish");
    });
    return;
  };

  return (
    <>
      {!show && <BackToHome />}
      {show && (
        <div className="mb-4">
          <Button
            color="danger"
            className="uppercase px-8 py-1 rounded-none border-2"
            outlined={true}
            onClick={() => navigate("/admin/inquiries")}
          >
            <span className="la la-undo text-xl mr-2"></span>
            戻る
          </Button>
        </div>
      )}
      <BaseCard>
        <div className="grid grid-cols-2 max-lg:grid-cols-1">
          <div className="z-20 p-4">
            <div className="grid grid-cols-1">
              <div className="w-full flex flex-col justify-center items-center pb-8">
                <h4 className="text-black text-2xl font-semibold">
                  お問い合わせ
                </h4>
                <p className="w-full m-0 p-0 text-left text-base">
                  お問い合わせ内容を確認するまで時間が掛かる場合がございます。
                  お急ぎの方はお電話ください。
                </p>
              </div>
            </div>
            <div className="grid grid-cols-1">
              <BaseSelectDate
                label={"申請日"}
                name={"request_date"}
                value={inquiriesEditData.request_date || null}
                changeValue={changeValue}
                disabled={readOnly}
              />
            </div>
            <div className="grid grid-cols-1">
              <BaseSearchSelect
                label={"マンション"}
                name={"apartment_id"}
                value={
                  role !== "Admin"
                    ? userData.apartment_id || null
                    : inquiriesEditData.apartment_id || null
                }
                disabled={role !== "Admin" ? true : readOnly}
                placeholder={"マンション"}
                objectName={"apartments"}
                textField={"apartment_name"}
                changeValue={changeValue}
              />
            </div>
            <div className="grid grid-cols-1">
              <BaseInput
                label={"お名前"}
                name={"customername"}
                value={inquiriesEditData.customername || null}
                placeholder={"お名前"}
                changeValue={changeValue}
                disabled={readOnly}
              />
            </div>
            <div className="grid grid-cols-1">
              <BaseInput
                label={"お部屋番号"}
                name={"room_number"}
                value={inquiriesEditData.room_number || null}
                placeholder={"お部屋番号"}
                changeValue={changeValue}
                disabled={readOnly}
              />
            </div>
            <div className="grid grid-cols-1">
              <BaseInput
                label={"連絡先"}
                name={"mobile"}
                value={inquiriesEditData.mobile || null}
                placeholder={"連絡先"}
                changeValue={changeValue}
                disabled={readOnly}
              />
            </div>
            <div className="grid grid-cols-1">
              <BaseInput
                label={"メールアドレス"}
                name={"email"}
                value={inquiriesEditData.email || null}
                placeholder={"メールアドレス"}
                changeValue={changeValue}
                disabled={readOnly}
              />
            </div>
            <div className="grid grid-cols-1">
              <BaseInput
                label={"題名"}
                name={"title"}
                value={inquiriesEditData.title || null}
                placeholder={"題名"}
                changeValue={changeValue}
                disabled={readOnly}
              />
            </div>
            <div className="grid grid-cols-1">
              <BaseTextarea
                label={"説明"}
                name={"comment"}
                value={inquiriesEditData.comment || null}
                placeholder={"説明"}
                changeValue={changeValue}
                className="h-32"
                disabled={readOnly}
              />
            </div>

            <div className="grid grid-cols-1 mb-8">
              <FileUpload
                id={id || null}
                multiple={true}
                name="files"
                changeValue={changeValue}
                readOnly={readOnly}
              />
            </div>

            {!readOnly && (
              <div className="grid grid-cols-1">
                <div className="flex flex-row justify-start items-center">
                  <BaseLoadButton
                    className="rounded-none px-8 py-2"
                    color="info"
                    icon="paper-plane"
                    outlined={true}
                    onClick={async () => await save()}
                  >
                    送信
                  </BaseLoadButton>
                  <div className="ml-8 max-md:ml-4 max-sm:ml-2">
                    <span>
                      ご記入頂いた個人情報につきましては、お問い合わせに <br />
                      のみ使用し、それ以外の目的では使用いたしません。
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="relative z-10 flex flex-row justify-center items-center h-full w-full max-lg:hidden">
            <div className="absolute top-0 right-0 bottom-0 -left-12 bg-transparent bg-inquiry bg-no-repeat bg-center bg-contain"></div>
          </div>
        </div>
      </BaseCard>
    </>
  );
};

export default CreateAndUpdate;
